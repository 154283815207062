import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { colors } from "../../../utils/styles"
import { formatDate } from "../../../utils/date"

export const BrazilianCreditBondsPlot = ({ data, width = 1875, height = 700, setSVG = () => {}, legend = () => {} }) => {
  const ref = useRef(null)

  useEffect(() => {
    if (!data || !data.length) return

    const svg = d3.select(ref.current).attr("width", width).attr("height", height)

    svg.selectAll("*").remove()

    const tooltip = d3
      .select("body")
      .append("div")
      .style("position", "absolute")
      .style("background", "rgba(0, 0, 0, 0.7)")
      .style("color", "white")
      .style("padding", "8px")
      .style("border-radius", "4px")
      .style("pointer-events", "none")
      .style("font-size", "12px")
      .style("white-space", "nowrap")
      .style("visibility", "hidden")
      .style("font-family", "'Segoe UI'")

    svg
      .append("defs")
      .append("clipPath")
      .attr("id", "clip")
      .append("rect")
      .attr("x", 40)
      .attr("y", 40)
      .attr("width", width - 80)
      .attr("height", height - 80)

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", 20)
      .attr("text-anchor", "middle")
      .style("font-size", "18px")
      .attr("font-weight", "bold")
      .text("Curva de Debêntures")
      .style("font-family", "'Segoe UI'")

    svg
      .append("text")
      .attr("x", width / 2)
      .attr("y", height - 10)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-family", "'Segoe UI'")
      .text("Duration")

    svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -height / 2)
      .attr("y", 10)
      .attr("text-anchor", "middle")
      .style("font-size", "14px")
      .style("font-family", "'Segoe UI'")
      .text("Spread")

    const xScale = d3
      .scaleLinear()
      .domain([Math.floor(d3.min(data, (d) => d.duration)) - 0.25, Math.ceil(d3.max(data, (d) => d.duration))])
      .range([40, width - 40])

    const yScale = d3
      .scaleLinear()
      .domain([Math.floor(d3.min(data, (d) => d.rate) - 5), Math.ceil(d3.max(data, (d) => d.rate) + 5)])
      .range([height - 40, 40])

    const xAxis = d3.axisBottom(xScale)
    const yAxis = d3.axisLeft(yScale)

    const xAxisGroup = svg
      .append("g")
      .attr("transform", `translate(0, ${height - 40})`)
      .call(xAxis)
    const yAxisGroup = svg.append("g").attr("transform", "translate(40, 0)").call(yAxis)

    const xGrid = d3
      .axisBottom(xScale)
      .tickSize(-height + 80)
      .tickFormat("")

    const yGrid = d3
      .axisLeft(yScale)
      .tickSize(-width + 80)
      .tickFormat("")

    svg
      .append("g")
      .attr("class", "grid")
      .attr("transform", `translate(0, ${height - 40})`)
      .call(xGrid)
      .selectAll("line")
      .style("stroke", "#e0e0e0")

    svg.append("g").attr("class", "grid").attr("transform", "translate(40, 0)").call(yGrid).selectAll("line").style("stroke", "#e0e0e0")

    const points = svg
      .append("g")
      .attr("clip-path", "url(#clip)")
      .selectAll("circle")
      .data(data)
      .enter()
      .append("circle")
      .attr("cx", (d) => xScale(d.duration))
      .attr("cy", (d) => yScale(d.rate))
      .attr("r", 5)
      .style("fill", colors.primary)
      .on("mouseover", (_, d) => {
        tooltip.style("visibility", "visible").html(
          `<strong>Nome:</strong> ${d.name || ""}<br/>
             <strong>Remuneração:</strong> ${legend(d.rate)}<br/>
             <strong>Duration:</strong> ${d.duration.toFixed(2)} anos<br/>
             <strong>Vencimento:</strong> ${formatDate(d.maturity) || ""}<br/><br/>
             <p>(*) O papel tem cláusula de Resgate ou Amortização Antecipado.</p>
             <p>(**) A cláusula já está no período de exercício.</p>
             <p>(#) Condição atual de negociação em “combo” dessas emissões.</p>
             `,
        )
      })
      .on("mousemove", (event) => {
        tooltip.style("top", event.pageY + 10 + "px").style("left", event.pageX + 10 + "px")
      })
      .on("mouseout", () => {
        tooltip.style("visibility", "hidden")
      })

    svg
      .append("g")
      .attr("clip-path", "url(#clip)")
      .selectAll("text.label")
      .data(data)
      .enter()
      .append("text")
      .attr("x", (d) => xScale(d.duration))
      .attr("y", (d) => yScale(d.rate) + 15)
      .attr("class", "label")
      .style("font-size", "10px")
      .style("font-family", "'Segoe UI'")
      .attr("text-anchor", "middle")
      .text((d) => d.ticker)

    const zoom = d3
      .zoom()
      .scaleExtent([1, 10])
      .on("zoom", (event) => {
        const newXScale = event.transform.rescaleX(xScale)
        const newYScale = event.transform.rescaleY(yScale)

        xAxisGroup.call(xAxis.scale(newXScale))
        yAxisGroup.call(yAxis.scale(newYScale))

        points.attr("cx", (d) => newXScale(d.duration)).attr("cy", (d) => newYScale(d.rate))

        svg
          .selectAll("text.label")
          .attr("x", (d) => newXScale(d.duration))
          .attr("y", (d) => newYScale(d.rate) + 15)
      })
      .on("start", () => svg.style("cursor", "grabbing"))
      .on("end", () => svg.style("cursor", "default"))

    svg.call(zoom).style("cursor", "grab")
  }, [data])

  useEffect(() => {
    if (setSVG) setSVG(ref.current)
  }, [setSVG, ref])

  return <svg ref={ref}></svg>
}
