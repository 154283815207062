import { RedoOutlined, WarningFilled } from "@ant-design/icons"
import { Alert, Button, Flex } from "antd"
import { spaces } from "../../utils/styles"

export const NewAssumptionsDetected = ({ alert = "New assumptions detected!", update = () => {} }) => {
  return (
    <Flex style={{ gap: spaces.space1, alignItems: "center" }}>
      <Alert message={alert} type="warning" showIcon icon={<WarningFilled />} />
      <Button type="primary" onClick={update} icon={<RedoOutlined />}>
        Update Projections
      </Button>
    </Flex>
  )
}
