import { Flex } from "antd"
import { TERA_SOURCE } from "../../hooks/templates/private-investments/useAssumptions"
import { colors, spaces } from "../../utils/styles"
import { PercentLabel } from "../atoms/formatters/PercentLabel"

export const AssumptionLabel = ({ value, source }) => {
  let backgroundColor = source === TERA_SOURCE ? colors.assumptionTera : colors.assumptionManager

  if (!value && value !== 0) backgroundColor = "transparent"

  return (
    <Flex style={{ backgroundColor, justifyContent: "center", borderRadius: 4, paddingLeft: spaces.space0, paddingRight: spaces.space0 }}>
      <PercentLabel value={value} decimalPlaces={2} />
    </Flex>
  )
}
