import { Tabs } from "antd"
import { BrazilianCreditBonds } from "./BrazilianCreditBonds"

export const Screening = () => {
  const items = [
    {
      key: "1",
      path: "screening/debentures",
      label: "Debêntures",
      children: <BrazilianCreditBonds />,
    },
  ]

  return <Tabs defaultActiveKey="1" items={items} />
}
