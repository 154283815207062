export const DimCustomerService = (supabase) => {
  const getAll = async () => {
    const { data } = await supabase.from("dim_customer").select("*").order("name")

    return data.map((item) => ({
      id: item.id,
      name: item.name,
    }))
  }

  return { getAll }
}
