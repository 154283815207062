export const FactPositionsService = (supabase) => {
  const getLastDateAvailable = async () => {
    const { data } = await supabase.from("fact_asset_allocation").select("date").order("date", { ascending: false }).limit(1)
    return data[0].date
  }

  const getPrivateInvestmentsFromCustomer = async ({ dimCustomerId }) => {
    const query = supabase.from("fact_asset_allocation").select("*")
    query.filter("dim_customer_id", "eq", dimCustomerId)
    query.filter("date", "eq", await getLastDateAvailable())
    const { data } = await query
    return data
  }

  return {
    getLastDateAvailable,
    getPrivateInvestmentsFromCustomer,
  }
}
