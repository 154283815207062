import { ExclamationCircleFilled } from "@ant-design/icons"
import { Button, Card, DatePicker, Empty, Flex, Table, Tooltip } from "antd"
import dayjs from "dayjs"
import { useExpectedReturn } from "../../../hooks/useAssetAllocation"
import { useAuth } from "../../../hooks/context/useAuth"
import { borders, colors, spaces } from "../../../utils/styles"
import { capitalizeFirstLetter } from "../../../utils/utils"
import { Loader } from "../../atoms/animations/Loader"
import { PercentLabel } from "../../atoms/formatters/PercentLabel"
import ScatterPlot from "../../molecules/charts/ScatterPlot"
import { NewExpectedReturnModal } from "../../molecules/modals/NewExpectedReturnModal"

export const ExpectedReturn = () => {
  const { isManagementTeam } = useAuth()
  const { isLoading, expectedReturn, showModal, date, availableDates, setShowModal, setDate, insertExpectedReturn } = useExpectedReturn()

  const HistoryColumn = (title, tooltip = "Histórico") => (
    <Flex>
      {title}
      <Tooltip title={tooltip}>
        <ExclamationCircleFilled style={{ marginLeft: spaces.space1, color: colors.primary }} />
      </Tooltip>
    </Flex>
  )

  const GenericTable = (dataSource) => (
    <Table
      dataSource={dataSource}
      columns={[
        {
          title: "",
          dataIndex: "name",
          key: "name",
          render: (value) => <b>{value}</b>,
        },
        {
          title: "Retorno",
          dataIndex: "return",
          key: "return",
          width: 100,
          align: "center",
          render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
        },
        {
          title: HistoryColumn("Volatilidade"),
          dataIndex: "volatility",
          key: "volatility",
          width: 100,
          align: "center",
          render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
        },
        {
          title: HistoryColumn("CVaR", "Histórico, p_value = 0.1"),
          dataIndex: "cvar",
          key: "cvar",
          width: 100,
          align: "center",
          render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
        },
        {
          title: HistoryColumn("Max Drawdown"),
          dataIndex: "max_drawdown",
          key: "max_drawdown",
          width: 150,
          align: "center",
          render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
        },
      ]}
      pagination={false}
      size="small"
      rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
      style={{ marginBottom: spaces.space2 }}
    />
  )

  if (isLoading) return <Loader />

  return (
    <>
      {showModal && <NewExpectedReturnModal onClose={() => setShowModal(false)} onSubmit={insertExpectedReturn} />}
      <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2 }}>
        <Flex gap={spaces.space2}>
          <DatePicker.MonthPicker
            value={dayjs(date)}
            allowClear={false}
            disabledDate={(current) =>
              !availableDates.map((availableDate) => dayjs(availableDate)).some((availableDate) => availableDate.isSame(current, "month"))
            }
            onChange={(date) => setDate(date.format("YYYY-MM-DD"))}
          />
          <Button type="primary" onClick={() => setShowModal(true)} disabled={!isManagementTeam}>
            Inserir novos retornos
          </Button>
        </Flex>
        {expectedReturn.length === 0 && (
          <Flex style={{ justifyContent: "center", width: "100%" }}>
            <Empty description="Sem retornos registrados" />
          </Flex>
        )}
        {expectedReturn.length > 0 && (
          <Flex gap={spaces.space2}>
            {["offshore", "onshore"].map((locale) => (
              <Card title={capitalizeFirstLetter(locale)} styles={{ body: { padding: spaces.space2 } }} width={800}>
                <ScatterPlot
                  key={locale}
                  data={expectedReturn
                    .filter((row) => row.locale === locale && row.return && row.volatility)
                    .map((row) => ({ x: row.volatility, y: row.return, name: row.name }))}
                  width={700}
                  height={625}
                  xAxisTitle={"Volatilidade"}
                  yAxisTitle={"Retorno"}
                />
                <Flex vertical>{GenericTable(expectedReturn.filter((row) => row.locale === locale))}</Flex>
              </Card>
            ))}
          </Flex>
        )}
      </Flex>
    </>
  )
}
