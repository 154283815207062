export const ExpectedDividendPerShareService = (supabase) => {
  const getAll = async () => {
    const { data } = await supabase.from("expected_dividend_per_share").select("*,dim_asset(*)")
    return data.map((item) => ({
      assetId: item.dim_asset.id,
      asset: item.dim_asset.name,
      dividend: item.dividend,
      year: item.year,
    }))
  }

  return { getAll }
}
