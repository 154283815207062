import * as d3 from "d3"
import React, { useEffect, useRef } from "react"

export const IPSPlot = ({ data, width = 800, height = 550, setSVG }) => {
  const ref = useRef()

  useEffect(() => {
    if (!data || !data.length) return

    const radius = Math.min(width, height) / 2

    const svg = d3.select(ref.current).attr("width", width).attr("height", height)

    svg.selectAll("*").remove()

    const g = svg.append("g").attr("transform", `translate(${width / 2 - 75}, ${height / 2})`)

    const colorMapping = {
      Cash: "#050505",
      "Core Bonds": "#626367",
      "Credit Bonds": "#ABCBFF",
      "Public Equity": "#006AFF",
      "Private Investments": "#408FFE",
      "Hedge Funds": "#C14A16",
      Gold: "#F17B46",
      Commodities: "#A96040",
      "Public Real Estate": "#D9612C",
    }

    const strategyColorMapping = {
      Defensive: "#253043",
      Diversifier: "#F15D1B",
      Performance: "#355675",
    }

    const getFontColor = (className, value) => {
      if (className === "Credit Bonds") return "black"
      return value === 1 ? "black" : "white"
    }
    const getStrategyColor = (strategy) => strategyColorMapping[strategy] || "#000000"

    const standardizeCase = (str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    const removeSuffix = (str) => str.replace(/ BZ$/, "")

    const color = d3.scaleOrdinal().domain(Object.keys(colorMapping)).range(Object.values(colorMapping))

    const groupedData = Array.from(
      d3.group(data, (d) => standardizeCase(d.strategy)),
      ([key, values]) => ({ key, values }),
    )

    const pie = d3.pie().value((d) => d.values.reduce((acc, curr) => acc + curr.target, 0))

    const arcInner = d3
      .arc()
      .innerRadius(0)
      .outerRadius((3 * radius) / 4)
    const arcOuter = d3
      .arc()
      .innerRadius((3 * radius) / 4)
      .outerRadius(radius)

    const outerLabelArc = d3
      .arc()
      .innerRadius(radius * 1.1)
      .outerRadius(radius * 1.1)

    const pieData = pie(groupedData)

    g.selectAll("path.inner")
      .data(pieData)
      .enter()
      .append("path")
      .attr("class", "inner")
      .attr("fill", (d) => getStrategyColor(d.data.key))
      .attr("d", arcInner)
      .attr("stroke", "white")
      .attr("stroke-width", 2)

    g.selectAll("text.inner")
      .data(pie(groupedData))
      .enter()
      .append("text")
      .attr("class", "inner")
      .attr("transform", (d) => `translate(${arcInner.centroid(d)})`)
      .attr("text-anchor", "middle")
      .attr("dy", ".35em")
      .attr("fill", (d) => getFontColor(d.data.key, Math.round(d.data.values.reduce((acc, curr) => acc + curr.target, 0) * 100)))
      .call((text) =>
        text
          .append("tspan")
          .attr("x", 0)
          .attr("dy", "-0.5em")
          .text((d) => d.data.key),
      )
      .call((text) =>
        text
          .append("tspan")
          .attr("x", 0)
          .attr("dy", "1.2em")
          .text((d) => {
            const value = d.data.values.reduce((acc, curr) => acc + curr.target, 0)
            return value > 0 ? `${Math.round(value * 100)}%` : ""
          }),
      )

    groupedData.forEach((strategy, i) => {
      const outerPie = d3
        .pie()
        .startAngle(pie(groupedData)[i].startAngle)
        .endAngle(pie(groupedData)[i].endAngle)
        .value((d) => d.target)

      g.selectAll(`path.outer-${i}`)
        .data(outerPie(strategy.values))
        .enter()
        .append("path")
        .attr("class", `outer-${i}`)
        .attr("fill", (d) => color(removeSuffix(d.data.class_name)))
        .attr("d", arcOuter)
        .attr("stroke", "white")
        .attr("stroke-width", 2)

      g.selectAll(`polyline.outer-${i}`)
        .data(outerPie(strategy.values))
        .enter()
        .append("polyline")
        .attr("class", `outer-${i}`)
        .attr("stroke", (d) => getFontColor(d.data.class_name, Math.round(d.data.target * 100)))
        .attr("stroke-width", 1)
        .attr("fill", "none")
        .attr("points", (d) => {
          if (Math.round(d.data.target * 100) === 1) {
            const posA = arcOuter.centroid(d)
            const posB = outerLabelArc.centroid(d)
            return [posA, posB, posB]
          }
          return null
        })

      g.selectAll(`text.outer-${i}`)
        .data(outerPie(strategy.values))
        .enter()
        .append("text")
        .attr("class", `outer-${i}`)
        .attr("transform", (d) => {
          if (Math.round(d.data.target * 100) === 1) {
            const pos = outerLabelArc.centroid(d)
            return `translate(${pos})`
          } else {
            return `translate(${arcOuter.centroid(d)})`
          }
        })
        .attr("text-anchor", (d) => {
          if (Math.round(d.data.target * 100) === 1) {
            const midAngle = (d.startAngle + d.endAngle) / 2
            return midAngle < Math.PI ? "start" : "end"
          }
          return "middle"
        })
        .attr("dy", ".35em")
        .attr("fill", (d) => getFontColor(d.data.class_name, Math.round(d.data.target * 100)))
        .text((d) => {
          const value = d.data.target
          return value > 0 ? `${Math.round(value * 100)}%` : ""
        })
    })

    const legendRectSize = 18
    const legendSpacing = 4
    const legendHeight = data.length * (legendRectSize + legendSpacing)

    const legend = g.append("g").attr("transform", `translate(${radius + 20}, ${-(legendHeight / 2)})`)

    const filteredData = data.filter((d) => d.target > 0)

    legend
      .selectAll("rect")
      .data(filteredData)
      .enter()
      .append("rect")
      .attr("x", 0)
      .attr("y", (d, i) => i * 20)
      .attr("width", 18)
      .attr("height", 18)
      .style("fill", (d) => color(removeSuffix(d.class_name)))

    legend
      .selectAll("text")
      .data(filteredData)
      .enter()
      .append("text")
      .attr("x", 24)
      .attr("y", (d, i) => i * 20 + 9)
      .attr("dy", ".35em")
      .text((d) => `${removeSuffix(d.class_name)}: ${Math.round(d.target * 100)}%`)
  }, [data, width, height])

  useEffect(() => {
    if (setSVG) setSVG(ref.current)
  }, [setSVG, ref])

  return <svg ref={ref}></svg>
}
