import dayjs from "dayjs"

export const generateYearSeries = (startYear, endYear) => {
  return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i)
}

export const generateQuarterSeries = (startYear, endYear) => {
  const quarters = ["Q1", "Q2", "Q3", "Q4"]
  const years = generateYearSeries(startYear, endYear)

  return years.map((year) => quarters.map((quarter) => `${quarter} ${year}`)).flat()
}

export const convertQuarterToDate = (date) => {
  const [year, quarter] = date.split("-")

  const mapDate = {
    Q1: `${year}-01-01`,
    Q2: `${year}-04-01`,
    Q3: `${year}-07-01`,
    Q4: `${year}-10-01`,
  }

  return mapDate[quarter]
}

export const convertDateToQuarter = (date) => {
  const dateObj = dayjs(date)
  const month = dateObj.month() + 1
  const year = dateObj.year()

  if (month <= 3) return `${year}-Q1`
  if (month <= 6) return `${year}-Q2`
  if (month <= 9) return `${year}-Q3`
  return `${year}-Q4`
}

export const convertDateToSemester = (date) => {
  const dateObj = new Date(date)
  const month = dateObj.getMonth() + 1
  const year = dateObj.getFullYear()
  return month <= 6 ? `${year}-S1` : `${year}-S2`
}

export const getNextQuarterAvailable = (disabledList) => {
  const now = dayjs()
  let year = now.year()
  let q = Math.floor(now.month() / 3) + 1
  const monthMap = { 1: "01", 2: "04", 3: "07", 4: "10" }

  while (true) {
    const quarterStr = `${year}-Q${q}`
    if (!disabledList.includes(quarterStr)) {
      return `${year}-${monthMap[q]}-01`
    }
    q++
    if (q > 4) {
      q = 1
      year++
    }
  }
}

export function formatDate(date) {
  if (!date) return ""
  const matches = date.match(/(\d{4})-(\d{2})-(\d{2})/)
  return `${matches[3]}/${matches[2]}/${matches[1]}`
}

export function getAllKeys(data) {
  let keys = []
  const traverse = (items) => {
    items.forEach((item) => {
      keys.push(item.key)
      if (item.children) {
        traverse(item.children)
      }
    })
  }
  traverse(data)
  return keys
}

export const formatDateTime = (date) => {
  const dataGMT0 = new Date(date)
  const dataGMTMinus3 = new Date(dataGMT0.getTime() - 3 * 60 * 60 * 1000)
  return dataGMTMinus3.toLocaleDateString("pt-BR", { timeZone: "UTC", hour: "2-digit", minute: "2-digit" })
}

export const countDaysUntilToday = (dateString) => {
  const date = new Date(dateString)
  const today = new Date()
  const diffTime = Math.abs(today - date)
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
}
