import { tables } from "../utils/utils"

export const AssetService = (supabase) => {
  const getAllAssets = async () => {
    const { data } = await supabase.from("dim_asset").select("*").order("name", { ascending: true })
    return data.map((asset) => ({ id: asset.id, name: asset.name }))
  }

  const getPrivateInvestments = async (name = null) => {
    const query = supabase.from(tables.VIEW_ASSET_CLASS_PARENT).select("asset_id, asset_name").in("parent_class_name", ["Private Investments"])

    if (name) query.filter("asset_name", "ilike", `%${name}%`)

    const { data } = await query.order("asset_name", { ascending: true })

    return data.map((asset) => ({ id: asset.asset_id, name: asset.asset_name }))
  }

  return {
    getAllAssets,
    getPrivateInvestments,
  }
}
