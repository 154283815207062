import * as d3 from "d3"
import { useEffect, useRef } from "react"

export const MainResultsCumulativePlot = ({ data, commitment, width = 800, height = 350 }) => {
  const ref = useRef()

  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove()

    const svg = d3.select(ref.current).attr("width", width).attr("height", height).append("g").attr("transform", "translate(50, -40)")

    svg
      .append("text")
      .attr("x", 350)
      .attr("y", 60)
      .attr("text-anchor", "middle")
      .attr("font-size", "18px")
      .attr("font-weight", "bold")
      .text("Main Results (Cumulative)")

    const chartGroup = svg.append("g").attr("transform", "translate(0, -60)")

    const getSumPerSemester = (data) => {
      const grouped = {}

      data.forEach((d) => {
        if (!grouped[d.semester]) grouped[d.semester] = { semester: d.semester, capitalCall: 0, unfundedCapital: 0, distribution: 0, nav: 0 }

        grouped[d.semester].capitalCall += (d.cumulativeContributionRate * commitment) / commitment
        grouped[d.semester].unfundedCapital += d.unfundedCapitalUsd / commitment
        grouped[d.semester].distribution += (d.cumulativeDistributionRate * commitment) / commitment
        grouped[d.semester].nav += d.navUsd / commitment
      })

      return Object.values(grouped)
    }

    const performed = getSumPerSemester(data.filter((d) => d.type === "performed"))
    const estimated = getSumPerSemester(data.filter((d) => d.type === "estimated"))

    if (estimated.length && performed.length) {
      const intersection = {
        semester: performed[performed.length - 1].semester,
        capitalCall: performed[performed.length - 1].capitalCall,
        unfundedCapital: performed[performed.length - 1].unfundedCapital,
        distribution: performed[performed.length - 1].distribution,
        nav: performed[performed.length - 1].nav,
      }

      estimated.unshift(intersection)
    }

    let minY = 0
    let maxY = 0

    performed.concat(estimated).forEach((row) => {
      maxY = Math.max(maxY, row.capitalCall, row.unfundedCapital, row.distribution, row.nav)
      minY = Math.min(minY, row.capitalCall, row.unfundedCapital, row.distribution, row.nav)
    })

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.semester))
      .range([0, 705])
      .padding(-1)

    const y = d3
      .scaleLinear()
      .domain([minY - 0.2, maxY + 3])
      .range([360, 140])

    const makeXGridlines = () => d3.axisBottom(x).ticks(data.length)

    const makeYGridlines = () => d3.axisLeft(y).ticks(10).tickFormat(d3.format(".2f"))

    chartGroup
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0,360)")
      .call(
        makeXGridlines()
          .tickSize(-(360 - 140))
          .tickFormat(""),
      )
      .selectAll("line")
      .style("stroke", "#ccc")

    chartGroup
      .append("g")
      .attr("transform", "translate(0,360)")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-70)")
      .attr("text-anchor", "middle")
      .attr("x", -27)
      .attr("dy", 0)

    chartGroup.append("g").call(d3.axisLeft(y).ticks(10).tickFormat(d3.format(".1f")))

    chartGroup
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0,0)")
      .call(makeYGridlines().tickSize(-705).tickFormat(""))
      .selectAll("line")
      .style("stroke", "#ccc")

    chartGroup
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -250)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "500")
      .text("MOIC")

    const capitalCallLine = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.capitalCall))
      .defined((d) => d.capitalCall !== null)

    const unfundedCapitalLine = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.unfundedCapital))
      .defined((d) => d.unfundedCapital !== null)

    const distributionLine = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.distribution))
      .defined((d) => d.distribution !== null)

    const navLine = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.nav))
      .defined((d) => d.nav !== null)

    chartGroup.append("path").datum(performed).attr("fill", "none").attr("stroke", "red").attr("stroke-width", 3).attr("d", capitalCallLine)

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", capitalCallLine)

    chartGroup.append("path").datum(performed).attr("fill", "none").attr("stroke", "black").attr("stroke-width", 3).attr("d", unfundedCapitalLine)

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "black")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", unfundedCapitalLine)

    chartGroup.append("path").datum(performed).attr("fill", "none").attr("stroke", "green").attr("stroke-width", 3).attr("d", distributionLine)

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", distributionLine)

    chartGroup.append("path").datum(performed).attr("fill", "none").attr("stroke", "blue").attr("stroke-width", 3).attr("d", navLine)

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", navLine)

    const legend = chartGroup.append("g").attr("transform", "translate(10,145)")

    legend
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 130)
      .attr("height", 65)
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("stroke-width", 1)

    legend.append("line").attr("x1", 10).attr("y1", 10).attr("x2", 30).attr("y2", 10).attr("stroke", "red").attr("stroke-width", 2)
    legend.append("text").attr("x", 40).attr("y", 10).text("Capital Call").attr("font-size", "10px").attr("alignment-baseline", "middle")

    legend.append("text").attr("x", 40).attr("y", 25).text("Unfunded Capital").attr("font-size", "10px").attr("alignment-baseline", "middle")
    legend.append("line").attr("x1", 10).attr("y1", 25).attr("x2", 30).attr("y2", 25).attr("stroke", "black").attr("stroke-width", 2)

    legend.append("text").attr("x", 40).attr("y", 40).text("Distribution").attr("font-size", "10px").attr("alignment-baseline", "middle")
    legend.append("line").attr("x1", 10).attr("y1", 40).attr("x2", 30).attr("y2", 40).attr("stroke", "green").attr("stroke-width", 2)

    legend.append("text").attr("x", 40).attr("y", 55).text("NAV").attr("font-size", "10px").attr("alignment-baseline", "middle")
    legend.append("line").attr("x1", 10).attr("y1", 55).attr("x2", 30).attr("y2", 55).attr("stroke", "blue").attr("stroke-width", 2)
  }, [data])

  return <svg ref={ref} style={{ minWidth: width, minHeight: height }} />
}
