export const FactBrazilianCreditBondsPrincingService = (supabase) => {
  const getFromDate = async (date, type) => {
    const { data } = await supabase.from("fact_brazilian_credit_bonds_pricing").select().eq("date", date).eq("spread_type", type)
    return data
  }

  const getAvailableDates = async () => {
    const { data } = await supabase.from("fact_brazilian_credit_bonds_pricing").select("date, count()").order("date", { ascending: false })
    return data.map((item) => item.date)
  }

  return { getFromDate, getAvailableDates }
}
