import { Tabs } from "antd"
import { AssetLiabilityManagement } from "./AssetLiabilityManagement"
import { ExpectedReturn } from "./ExpectedReturn"
import { IPS } from "./IPS"
import { PortfolioModel } from "./PortfolioModel"

export const AssetAllocation = () => {
  const items = [
    {
      key: "ips",
      label: "IPS",
      children: <IPS />,
    },
    {
      key: "carteira-modelo",
      label: "Carteira Modelo",
      children: <PortfolioModel />,
    },
    {
      key: "expected-return",
      label: "Expected Return",
      children: <ExpectedReturn />,
    },
    {
      key: "asset-liability-management",
      label: "Asset Liability Management",
      children: <AssetLiabilityManagement />,
    },
  ]

  return <Tabs defaultActiveKey="4" items={items} />
}
