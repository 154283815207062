import { useEffect, useMemo, useState } from "react"
import { FactBrazilianCreditBondsPrincingService } from "../service/data-warehouse/FactBrazilianCreditBondsPrincingService"
import { useSupabase } from "./context/useSupabase"

export const BRAZILIAN_CREDIT_BONDS_TYPE = [
  {
    label: "% DI",
    value: "DI_PERCENTUAL",
    legend: (value) => `${value.toFixed(2)}% CDI`,
  },
  {
    label: "DI+",
    value: "DI_SPREAD",
    legend: (value) => `CDI + ${value.toFixed(2)}%`,
  },
  {
    label: "IPCA+",
    value: "IPCA_SPREAD",
    legend: (value) => `IPCA + ${value.toFixed(2)}%`,
  },
]

export const useBrazilianCreditBonds = () => {
  const [date, setDate] = useState(null)
  const [availableDates, setAvailableDates] = useState([])
  const [result, setResult] = useState([])
  const [filterType, setFilterType] = useState(BRAZILIAN_CREDIT_BONDS_TYPE[0])

  const supabase = useSupabase()
  const service = useMemo(() => FactBrazilianCreditBondsPrincingService(supabase), [supabase])

  useEffect(() => {
    service.getAvailableDates().then(setAvailableDates)
  }, [])

  useEffect(() => {
    availableDates && availableDates.length > 0 && !date && setDate(availableDates[0])
  }, [availableDates])

  useEffect(() => {
    date && service.getFromDate(date, filterType.value).then(setResult)
  }, [date, filterType])

  return {
    date,
    setDate,
    filterType,
    setFilterType,
    availableDates,
    result,
  }
}
