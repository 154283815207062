import axios from "axios"
import { convertDateToQuarter } from "../utils/date"
import { tables } from "../utils/utils"

export const PrivateInvestmentsService = (supabase) => {
  const getAssumptionsByFund = async (idFund) => {
    const { data } = await supabase
      .from(tables.PRIVATE_INVESTMENT_ASSUMPTION)
      .select("*")
      .eq("asset_id", idFund)
      .is("deleted_at", null)
      .order("quarter")

    if (!data.length) return []

    const capitalCallLabel = "Capital Call"
    const distributionLabel = "Distribution"

    const quartersAggregated = data.reduce(
      (acc, { capital_call, capital_call_source, distribution, distribution_source, quarter }) => {
        const quarterFormatted = convertDateToQuarter(quarter)
        acc[capitalCallLabel][quarterFormatted] = { value: capital_call, source: capital_call_source }
        acc[distributionLabel][quarterFormatted] = { value: distribution, source: distribution_source }

        return acc
      },
      {
        [capitalCallLabel]: { type: capitalCallLabel },
        [distributionLabel]: { type: distributionLabel },
      },
    )

    return Object.values(quartersAggregated)
  }

  const getInfo = async (idFund) => {
    const { data } = await supabase
      .from(tables.PRIVATE_INVESTMENT)
      .select("vintage, end_of_investment_period, fund_conclusion, mandate")
      .eq("asset_id", idFund)

    return data?.[0]
  }

  const getAssumptionsAndInfo = async () => {
    const { data } = await supabase
      .from(tables.PRIVATE_INVESTMENT_ASSUMPTION)
      .select(
        `
          *,
          dim_asset(
            name,
            ${tables.PRIVATE_INVESTMENT}(vintage, end_of_investment_period, fund_conclusion, mandate),
            ${tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS}(quarter, cumulative_contribution_rate, cumulative_distribution_rate, moic)
          )
       `,
      )
      .is("deleted_at", null)
      .order("dim_asset(name), quarter")
      .order("quarter", { foreignTable: `dim_asset.${tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS}`, ascending: false })
      .order("type", { foreignTable: `dim_asset.${tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS}`, ascending: false })
      .limit(1, { foreignTable: `dim_asset.${tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS}` })

    const aggregated = {}

    for (const row of data) {
      const idFund = row.asset_id
      const fund = row.dim_asset.name
      const quarter = convertDateToQuarter(row.quarter)

      if (!aggregated[fund]) {
        const info = { ...row.dim_asset[tables.PRIVATE_INVESTMENT] }

        const projections = row.dim_asset[tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS].sort((a, b) => b.quarter.localeCompare(a.quarter))
        const lastProjection = projections.length > 0 ? projections[0] : {}

        aggregated[fund] = {
          capitalCall: { ...info, idFund, total: lastProjection.cumulative_contribution_rate || 0, moic: lastProjection.moic },
          distribution: { ...info, idFund, total: lastProjection.cumulative_distribution_rate || 0, moic: lastProjection.moic },
        }
      }

      aggregated[fund]["capitalCall"][quarter] = { value: row.capital_call, source: row.capital_call_source }
      aggregated[fund]["distribution"][quarter] = { value: row.distribution, source: row.distribution_source }
    }

    return Object.entries(aggregated).reduce((acc, [fund, data]) => {
      const { capitalCall, distribution } = data
      acc.push({ fund, type: "capitalCall", ...capitalCall })
      acc.push({ fund, type: "distribution", ...distribution })

      return acc
    }, [])
  }

  const updateVintage = async (vintage, idFund) => {
    await supabase.from(tables.PRIVATE_INVESTMENT).upsert({ asset_id: idFund, vintage, updated_at: new Date() }, { onConflict: "asset_id" })
  }

  const updateEndOfInvestmentPeriod = async (end_of_investment_period, idFund) => {
    await supabase
      .from(tables.PRIVATE_INVESTMENT)
      .upsert({ asset_id: idFund, end_of_investment_period, updated_at: new Date() }, { onConflict: "asset_id" })
  }

  const updateFundConclusion = async (fund_conclusion, idFund) => {
    await supabase.from(tables.PRIVATE_INVESTMENT).upsert({ asset_id: idFund, fund_conclusion, updated_at: new Date() }, { onConflict: "asset_id" })
  }

  const updateMandate = async (mandate, idFund) => {
    await supabase.from(tables.PRIVATE_INVESTMENT).upsert({ asset_id: idFund, mandate, updated_at: new Date() }, { onConflict: "asset_id" })
  }

  const newAssumption = async (data, idFund) => {
    await supabase.from(tables.PRIVATE_INVESTMENT_ASSUMPTION).insert({ ...data, asset_id: idFund })
  }

  const deleteAssumption = async (quarter, idFund) => {
    await supabase.from(tables.PRIVATE_INVESTMENT_ASSUMPTION).update({ deleted_at: new Date() }).eq("quarter", quarter).eq("asset_id", idFund)
  }

  const refreshProjections = async (token) => {
    await axios.post(
      `${process.env.REACT_APP_TERA_ETL}/private-investments-projections/refresh`,
      {},
      { headers: { Authorization: `Bearer ${token}` } },
    )
  }

  const getLastAssumptionUpdateDate = async () => {
    const { data: lastInsert } = await supabase
      .from(tables.PRIVATE_INVESTMENT_ASSUMPTION)
      .select("created_at")
      .is("deleted_at", null)
      .order("created_at", { ascending: false })
      .limit(1)

    const { data: lastDelete } = await supabase
      .from(tables.PRIVATE_INVESTMENT_ASSUMPTION)
      .select("deleted_at")
      .not("deleted_at", "is", null)
      .order("deleted_at", { ascending: false })
      .limit(1)

    console.log("lastInsert", lastInsert)
    console.log("lastDelete", lastDelete)

    return lastInsert?.[0]?.created_at > lastDelete?.[0]?.deleted_at ? lastInsert?.[0]?.created_at : lastDelete?.[0]?.deleted_at
  }

  const getTotalsPerformed = async (idFund) => {
    const { data } = await supabase
      .from(tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS)
      .select("cumulative_contribution_rate, cumulative_distribution_rate")
      .order("quarter", { ascending: false })
      .eq("dim_asset_id", idFund)
      .eq("type", "performed")

    return { contribution: data?.[0]?.cumulative_contribution_rate, distribution: data?.[0]?.cumulative_distribution_rate }
  }

  return {
    getInfo,
    getAssumptionsAndInfo,
    getAssumptionsByFund,
    newAssumption,
    deleteAssumption,
    updateVintage,
    updateEndOfInvestmentPeriod,
    updateFundConclusion,
    updateMandate,
    refreshProjections,
    getLastAssumptionUpdateDate,
    getTotalsPerformed,
  }
}
