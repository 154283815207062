import * as d3 from "d3"
import { useEffect, useRef } from "react"

export const RateOfContributionAndDistributionPlot = ({ data, width = 800, height = 310 }) => {
  const ref = useRef()

  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove()

    const svg = d3.select(ref.current).attr("width", width).attr("height", height).append("g").attr("transform", "translate(50, -40)")

    svg
      .append("text")
      .attr("x", 350)
      .attr("y", 60)
      .attr("text-anchor", "middle")
      .attr("font-size", "18px")
      .attr("font-weight", "bold")
      .text("Rate of Contribution and Distribution")

    const chartGroup = svg.append("g").attr("transform", "translate(0, -60)")

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.semester))
      .range([0, 705])
      .padding(-1)

    const maxContributionRate = d3.max(data, (d) => d.cumulativeContributionRate)
    const maxDistributionRate = d3.max(data, (d) => d.cumulativeDistributionRate)
    const maxY = Math.max(maxContributionRate + 0.1, maxDistributionRate + 0.1, 1)

    const y = d3.scaleLinear().domain([0, maxY]).range([360, 140])

    const makeXGridlines = () => d3.axisBottom(x).ticks(data.length)

    const makeYGridlines = () => d3.axisLeft(y).ticks(10).tickFormat(d3.format(".2f"))

    chartGroup
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0,360)")
      .call(
        makeXGridlines()
          .tickSize(-(360 - 140))
          .tickFormat(""),
      )
      .selectAll("line")
      .style("stroke", "#ccc")

    chartGroup
      .append("g")
      .attr("transform", "translate(0,360)")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-70)")
      .attr("text-anchor", "middle")
      .attr("x", -27)
      .attr("dy", 0)

    chartGroup.append("g").call(d3.axisLeft(y).ticks(10).tickFormat(d3.format(".1f")))

    chartGroup
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0,0)")
      .call(makeYGridlines().tickSize(-705).tickFormat(""))
      .selectAll("line")
      .style("stroke", "#ccc")

    chartGroup
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -250)
      .attr("y", -30)
      .attr("text-anchor", "middle")
      .attr("font-size", "16px")
      .attr("font-weight", "500")
      .text("Rate (% commitment)")

    const getMaxPerSemester = (data) => {
      const grouped = {}
      data.forEach((d) => {
        if (!grouped[d.semester] || d.quarter > grouped[d.semester].quarter) {
          grouped[d.semester] = d
        }
      })
      return Object.values(grouped)
    }

    const performed = getMaxPerSemester(data.filter((d) => d.type === "performed"))
    const estimated = getMaxPerSemester(data.filter((d) => d.type === "estimated"))

    if (estimated.length && performed.length) {
      const intersection = {
        semester: performed[performed.length - 1].semester,
        cumulativeContributionRate: performed[performed.length - 1].cumulativeContributionRate,
        cumulativeDistributionRate: performed[performed.length - 1].cumulativeDistributionRate,
      }

      estimated.unshift(intersection)
    }

    const contributionLine = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.cumulativeContributionRate))
      .defined((d) => d.cumulativeContributionRate !== null)

    const distributionLine = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.cumulativeDistributionRate))
      .defined((d) => d.cumulativeDistributionRate !== null)

    chartGroup.append("path").datum(performed).attr("fill", "none").attr("stroke", "red").attr("stroke-width", 3).attr("d", contributionLine)

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "red")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", contributionLine)

    chartGroup.append("path").datum(performed).attr("fill", "none").attr("stroke", "green").attr("stroke-width", 3).attr("d", distributionLine)

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "green")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", distributionLine)

    const legend = chartGroup.append("g").attr("transform", "translate(10,145)")

    legend
      .append("rect")
      .attr("x", 0)
      .attr("y", 0)
      .attr("width", 120)
      .attr("height", 35)
      .attr("fill", "white")
      .attr("stroke", "black")
      .attr("stroke-width", 1)

    legend.append("line").attr("x1", 10).attr("y1", 10).attr("x2", 30).attr("y2", 10).attr("stroke", "red").attr("stroke-width", 2)
    legend.append("text").attr("x", 40).attr("y", 10).text("Contribution").attr("font-size", "10px").attr("alignment-baseline", "middle")

    legend.append("line").attr("x1", 10).attr("y1", 25).attr("x2", 30).attr("y2", 25).attr("stroke", "green").attr("stroke-width", 2)
    legend.append("text").attr("x", 40).attr("y", 25).text("Distribution").attr("font-size", "10px").attr("alignment-baseline", "middle")
  }, [data])

  return <svg ref={ref} style={{ minWidth: width, minHeight: height }} />
}
