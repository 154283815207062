import { CaretDownOutlined, CaretUpOutlined, ExclamationCircleFilled } from "@ant-design/icons"
import { Empty, Flex, Select, Table, Tooltip } from "antd"
import { useProjections } from "../../../hooks/templates/private-investments/useProjections"
import { formatDateTime } from "../../../utils/date"
import { CAGR, DPI, IRR } from "../../../utils/latex"
import { colors, spaces } from "../../../utils/styles"
import { LoaderFullScreen } from "../../atoms/animations/Loader"
import { MoneyLabel } from "../../atoms/formatters/MoneyLabel"
import { PercentLabel } from "../../atoms/formatters/PercentLabel"
import { SimpleRoundedValueLabel } from "../../atoms/formatters/SimpleRoundedValueLabel"
import { CustomerOrFundSelect } from "../../atoms/select/CustomerOrFundSelect"
import { DPIPlot } from "../../molecules/charts/private-investments/DPIPlot"
import { GrowthRatePlot } from "../../molecules/charts/private-investments/GrowthRatePlot"
import { MainResultsCumulativePlot } from "../../molecules/charts/private-investments/MainResultsCumulativePlot"
import { MainResultsPlot } from "../../molecules/charts/private-investments/MainResultsPlot"
import { NetCashFlowPlot } from "../../molecules/charts/private-investments/NetCashFlowPlot"
import { RateOfContributionAndDistributionPlot } from "../../molecules/charts/private-investments/RateOfContributionAndDistributionPlot"
import { NewAssumptionsDetected } from "../../molecules/NewAssumptionsDetected"

export const Projections = () => {
  const { customer, setCustomer, assets, asset, setAsset, data, isNotUpdatedAnalysis, isLoading, update } = useProjections()

  const commitment = data?.[0]?.commitmentUsd

  const formula = ({ latexString, title }) => (
    <Flex style={{ justifyContent: "center" }}>
      {title}
      <Tooltip title={<div dangerouslySetInnerHTML={{ __html: latexString }} />}>
        <ExclamationCircleFilled style={{ marginLeft: spaces.space0, color: colors.primary }} />
      </Tooltip>
    </Flex>
  )

  const tableColumns = [
    { title: "Quarter", dataIndex: "quarter", key: "quarter", align: "center", minWidth: 75 },
    {
      title: "Capital Call",
      dataIndex: "contributionUsd",
      key: "contributionUsd",
      align: "center",
      minWidth: 180,
      render: (value) => (
        <Flex style={{ alignItems: "center", justifyContent: "center", gap: spaces.space1, color: "red" }}>
          {value ? <CaretDownOutlined style={{ color: "red" }} /> : null}
          <MoneyLabel value={value} currency={"USD"} />
        </Flex>
      ),
    },
    {
      title: "Contribution (%)",
      dataIndex: "contributionRate",
      key: "contributionRate",
      align: "center",
      minWidth: 100,
      render: (value) => (
        <Flex style={{ alignItems: "center", justifyContent: "center", gap: spaces.space1, color: "red" }}>
          {value ? <CaretDownOutlined style={{ color: "red" }} /> : null}
          <PercentLabel value={value} decimalPlaces={1} />
        </Flex>
      ),
    },
    {
      title: "Cumulative Contribution (%)",
      dataIndex: "cumulativeContributionRate",
      key: "cumulativeContributionRate",
      align: "center",
      minWidth: 100,
      render: (value) => <PercentLabel value={value} decimalPlaces={1} />,
    },
    {
      title: "Distribution",
      dataIndex: "distributionUsd",
      key: "distributionUsd",
      align: "center",
      minWidth: 200,
      render: (value) => (
        <Flex style={{ alignItems: "center", justifyContent: "center", gap: spaces.space1, color: "green" }}>
          {value ? <CaretUpOutlined style={{ color: "green" }} /> : null}
          <MoneyLabel value={value} currency={"USD"} />
        </Flex>
      ),
    },
    {
      title: "Distribution (%)",
      dataIndex: "distributionRate",
      key: "distributionRate",
      align: "center",
      minWidth: 100,
      render: (value) => (
        <Flex style={{ alignItems: "center", justifyContent: "center", gap: spaces.space1, color: "green" }}>
          {value ? <CaretUpOutlined style={{ color: "green" }} /> : null}
          <PercentLabel value={value} decimalPlaces={1} />
        </Flex>
      ),
    },
    {
      title: "Cumulative Distribution (%)",
      dataIndex: "cumulativeDistributionRate",
      key: "cumulativeDistributionRate",
      align: "center",
      minWidth: 100,
      render: (value) => <PercentLabel value={value} decimalPlaces={1} />,
    },
    {
      title: "Unfunded Capital",
      dataIndex: "unfundedCapitalUsd",
      key: "unfundedCapitalUsd",
      align: "center",
      minWidth: 200,
      render: (value) => <MoneyLabel value={value} currency={"USD"} />,
    },
    {
      title: "Net Cash Flow",
      dataIndex: "netCashFlowUsd",
      key: "netCashFlowUsd",
      align: "center",
      minWidth: 200,
      render: (value) => {
        if (!value) return "-"

        if (value > 0)
          return (
            <div style={{ color: "green" }}>
              <MoneyLabel value={value} currency={"USD"} />
            </div>
          )

        if (value < 0)
          return (
            <Flex style={{ color: "red", alignItems: "center", justifyContent: "center" }}>
              (<MoneyLabel value={Math.abs(value)} currency={"USD"} />)
            </Flex>
          )
      },
    },
    {
      title: formula({ latexString: CAGR, title: "CAGR (%)" }),
      dataIndex: "growthRate",
      key: "growthRate",
      align: "center",
      minWidth: 95,
      render: (value) => <PercentLabel value={value} decimalPlaces={1} />,
    },
    {
      title: formula({ latexString: DPI, title: "DPI (%)" }),
      dataIndex: "dpi",
      key: "dpi",
      align: "center",
      minWidth: 95,
      render: (value) => <PercentLabel value={value} decimalPlaces={1} />,
    },
    {
      title: formula({ latexString: IRR, title: "IRR (%)" }),
      dataIndex: "irr",
      key: "irr",
      align: "center",
      minWidth: 95,
      render: (value) => <PercentLabel value={value} decimalPlaces={1} />,
    },
    {
      title: "MOIC",
      dataIndex: "moic",
      key: "moic",
      align: "center",
      minWidth: 95,
      render: (value) => <SimpleRoundedValueLabel value={value} />,
    },
    {
      title: "NAV",
      dataIndex: "navUsd",
      key: "navUsd",
      align: "center",
      minWidth: 200,
      render: (value) => <MoneyLabel value={value} currency={"USD"} />,
    },
  ]

  const estimated = data?.filter((d) => d.type === "estimated")
  const performed = data?.filter((d) => d.type === "performed")

  const renderTable = (title, data) => {
    if (!data?.length) return <></>

    return (
      <>
        <h2>{title}</h2>
        <Table dataSource={data.sort((a, b) => a.quarter.localeCompare(b.quarter))} columns={tableColumns} size="small" pagination={false} />
      </>
    )
  }

  return (
    <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2 }}>
      <Flex style={{ gap: spaces.space1, alignItems: "center" }}>
        <CustomerOrFundSelect mode="customer" value={customer} setValue={setCustomer} />
        {customer && (
          <>
            <Flex style={{ marginLeft: spaces.space1, gap: spaces.space1, alignItems: "center" }}>
              <b>Fund:</b>
              <Select style={{ width: 300 }} options={assets} value={asset} onChange={setAsset} disabled={!customer || !assets?.length} />
            </Flex>
            <Flex style={{ marginLeft: spaces.space1, gap: spaces.space1, alignItems: "center" }}>
              <b>Commitment:</b>
              <MoneyLabel value={commitment} currency={"USD"} />
            </Flex>
            <Flex style={{ marginLeft: spaces.space1, gap: spaces.space1, alignItems: "center" }}>
              <b>Last update:</b>
              {formatDateTime(data?.[0]?.createdAt)}
              {isNotUpdatedAnalysis && <NewAssumptionsDetected update={update} />}
            </Flex>
          </>
        )}
      </Flex>
      {!customer && <Empty description="Select a customer to view the private investment projections" image={Empty.PRESENTED_IMAGE_SIMPLE} />}
      {customer && !data?.length && <LoaderFullScreen showRandomFacts />}
      {customer && data?.length && (
        <Flex vertical gap={spaces.space1}>
          <Flex gap={spaces.space2} style={{ justifyContent: "center" }}>
            <RateOfContributionAndDistributionPlot data={data} />
            <GrowthRatePlot data={data} />
          </Flex>
          <Flex gap={spaces.space2} style={{ justifyContent: "center" }}>
            <DPIPlot data={data} />
            <NetCashFlowPlot data={data} />
          </Flex>
          <Flex gap={spaces.space2} style={{ justifyContent: "center" }}>
            <MainResultsPlot data={data} commitment={commitment} />
            <MainResultsCumulativePlot data={data} commitment={commitment} />
          </Flex>
          <Flex gap={spaces.space2} vertical>
            {renderTable("Performed", performed)}
            {renderTable("Estimated", estimated)}
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}
