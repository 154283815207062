export const spaces = {
  space0: 4,
  space1: 8,
  space2: 16,
  space3: 24,
  space4: 32,
  space5: 40,
  space6: 48,
  space7: 56,
  space8: 64,
  space9: 72,
}

export const colors = {
  primary: "#f84600",
  primaryLight: "#f8958e",
  gray: "#ececec",
  darkGray: "#3a3a3a",
  green: "#217346",
  greenLight: "#51CD89",
  softGreen: "#90b9a2",
  blue: "#61bffc",
  assumptionTera: "lightblue",
  assumptionManager: "#f8958e",
}

export const borders = {
  default: `1px solid #cbcbcc`,
}
