import { Flex, Select } from "antd"
import { useEffect, useState } from "react"
import { useSupabase } from "../../../hooks/context/useSupabase"
import { spaces } from "../../../utils/styles"
import { tables } from "../../../utils/utils"

export const MODES = {
  CUSTOMER: "customer",
  FUND: "fund",
}

export const CustomerOrFundSelect = ({
  mode = MODES.CUSTOMER,
  value,
  setValue,
  setId,
  setName,
  isToUseNameAsValue = false,
  width = 240,
  customList = [],
  hasDefaultOption = true,
  hideLabel = false,
}) => {
  const supabase = useSupabase()
  const [items, setItems] = useState([])

  useEffect(() => {
    if (!mode || !supabase) return () => {}

    if (customList.length) {
      setItems(customList)
      return () => {}
    }

    supabase
      .from(mode === MODES.CUSTOMER ? tables.DIM_CUSTOMER : tables.DIM_TERA_FUND)
      .select("*")
      .order("name")
      .then(({ data }) => setItems(data))
  }, [mode])

  const defaultOption = { value: "all", label: "Todos" }
  const options = items.map((item) => ({
    value: isToUseNameAsValue ? item.name : item.id,
    label: item.name,
  }))

  if (hasDefaultOption) options.unshift(defaultOption)

  const onChange = (value) => {
    if (value === defaultOption.value) return setValue(null)
    setValue && setValue(value)
    setId && setId(value)
    setName && setName(items.find((item) => (isToUseNameAsValue ? item.name : item.id) === value).name)
  }

  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      {!hideLabel && <b>{mode === MODES.CUSTOMER ? "Cliente: " : "Fundo: "}</b>}
      <Select style={{ width }} options={options} value={value} onChange={onChange} defaultValue={hasDefaultOption && defaultOption} />
    </Flex>
  )
}
