import { useEffect, useState } from "react"
import { useService } from "../../context/useService"
import { useToast } from "../../context/useToast"
import { useLoading } from "../../utils/useLoading"

export const usePrivateInvestmentInfo = (fundDefault) => {
  const [funds, setFunds] = useState([])
  const [selectedFund, setSelectedFund] = useState(fundDefault)
  const [assumptions, setAssumptions] = useState([])
  const [info, setInfo] = useState(null)
  const [performedContribution, setPerformedContribution] = useState(0)
  const [performedDistribution, setPerformedDistribution] = useState(0)
  const [estimatedContribution, setEstimatedContribution] = useState(0)
  const [estimatedDistribution, setEstimatedDistribution] = useState(0)

  const { assetService, privateInvestmentsService } = useService()
  const toast = useToast()
  const { reload, refresh } = useLoading()

  useEffect(() => {
    assetService.getPrivateInvestments().then(setFunds)
  }, [reload])

  useEffect(() => {
    if (!selectedFund) return () => {}
    privateInvestmentsService.getAssumptionsByFund(selectedFund).then(setAssumptions)
    privateInvestmentsService.getInfo(selectedFund).then(setInfo)
  }, [selectedFund, reload])

  useEffect(() => {
    if (!selectedFund) return () => {}

    privateInvestmentsService.getTotalsPerformed(selectedFund).then((totals) => {
      const contribution = (totals.contribution || 0) * 100
      const distribution = (totals.distribution || 0) * 100

      setPerformedContribution(contribution)
      setPerformedDistribution(distribution)

      if (!assumptions || !assumptions.length) {
        setEstimatedContribution(0)
        setEstimatedDistribution(0)
        return
      }

      setEstimatedContribution(
        Object.values(assumptions.find((a) => a.type === "Capital Call")).reduce((acc, { value }) => acc + (value || 0) * 100, contribution) || 0,
      )

      setEstimatedDistribution(
        Object.values(assumptions.find((a) => a.type === "Distribution")).reduce((acc, { value }) => acc + (value || 0) * 100, distribution) || 0,
      )
    })
  }, [assumptions])

  const updateVintage = async (vintage) => {
    await privateInvestmentsService.updateVintage(vintage, selectedFund)
    toast.success({ message: "Vintage atualizado" })
    refresh()
  }

  const updateEndOfInvestmentPeriod = async (endOfInvestmentPeriod) => {
    await privateInvestmentsService.updateEndOfInvestmentPeriod(endOfInvestmentPeriod, selectedFund)
    toast.success({ message: "Expected End of Investment Period updated" })
    refresh()
  }

  const updateFundConclusion = async (endOfFund) => {
    await privateInvestmentsService.updateFundConclusion(endOfFund, selectedFund)
    toast.success({ message: "Expected Fund Conclusion updated" })
    refresh()
  }

  const updateMandate = async (mandate) => {
    await privateInvestmentsService.updateMandate(mandate, selectedFund)
    toast.success({ message: "Mandate updated" })
    refresh()
  }

  const newAssumption = async (values) => {
    await privateInvestmentsService.newAssumption(values, selectedFund)
    toast.success({ message: "Assumption created" })
    refresh()
  }

  const deleteAssumption = async (quarter) => {
    await privateInvestmentsService.deleteAssumption(quarter, selectedFund)
    toast.success({ message: "Assumption deleted" })
    refresh()
  }

  return {
    assumptions,
    funds,
    selectedFund,
    info,
    performedContribution,
    performedDistribution,
    estimatedContribution,
    estimatedDistribution,
    setSelectedFund,
    updateVintage,
    updateEndOfInvestmentPeriod,
    updateFundConclusion,
    updateMandate,
    newAssumption,
    deleteAssumption,
  }
}
