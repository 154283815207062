import { ASSET_LOCALES } from "../utils/enums"

export const PortfolioModelService = (supabase) => {
  const getAvailableDates = async () => {
    const { data: portfolioModels } = await supabase
      .from("portfolio_model")
      .select("date")
      .filter("deleted_at", "is", null)
      .order("date", { ascending: false })

    return portfolioModels.map((item) => item.date)
  }

  const getPortfolioModelByDate = async (date) => {
    const {
      data: [portfolioModel],
    } = await supabase
      .from("portfolio_model")
      .select("*")
      .filter("deleted_at", "is", null)
      .filter("date", "eq", date)
      .order("date", { ascending: false })
      .limit(1)

    if (!portfolioModel) return null

    const { data: portfolioModelClassWeights } = await supabase
      .from("portfolio_model_weight")
      .select("*, dim_asset_class (*, dim_benchmark(*))")
      .eq("portfolio_model_id", portfolioModel.id)

    const formatted = portfolioModelClassWeights.map((item) => ({
      assetClassId: item.dim_asset_class.id,
      assetClass: item.dim_asset_class.name,
      benchmark: item.dim_asset_class.dim_benchmark.name,
      ticker: item.dim_asset_class.dim_benchmark.bloomberg_ticker,
      weight: item.weight,
      locale: item.dim_asset_class.locale,
      tacticalPosition: item.tactical_position,
      strategy: item.dim_asset_class.strategy,
    }))

    const calculateStatistic = async (locale) => {
      const { data: expectedReturns } = await supabase.from("fact_expected_return").select("*").eq("date", portfolioModel.date).eq("locale", locale)

      if (expectedReturns.length === 0) return null

      const { pounded, total } = portfolioModelClassWeights.reduce(
        (acc, { weight, dim_asset_class: { name: assetClassName, locale: portfolioModelLocaleRow } }) => {
          if (locale != portfolioModelLocaleRow) return acc
          const expectedReturn = expectedReturns.find((item) => item.name === assetClassName)?.return || 0
          return { pounded: acc.pounded + expectedReturn * weight, total: acc.total + weight }
        },
        { pounded: 0, total: 0 },
      )

      const cpi = expectedReturns.find((item) => item.name.includes("Inflation"))?.return

      const expectedReturn = pounded / total
      const expectedReturnLiquid = expectedReturn * 0.85
      const expectedReturnRealLiquid = expectedReturnLiquid - cpi

      const volatility = ASSET_LOCALES.offshore.value === locale ? 0.07 : 0.04

      return {
        expectedReturn,
        expectedReturnLiquid,
        expectedReturnRealLiquid,
        cpi,
        volatility,
        sharpeRatio: (expectedReturnLiquid - cpi) / volatility,
        maxDD: locale === ASSET_LOCALES.offshore.value ? 0.23 : 0.13,
      }
    }

    return {
      id: portfolioModel.id,
      date: portfolioModel.date,
      onshore: {
        weight: portfolioModel.onshore_weight,
        portfolio: formatted.filter((item) => item.locale === ASSET_LOCALES.onshore.value).sort((a, b) => a.assetClass.localeCompare(b.assetClass)),
        statistics: await calculateStatistic(ASSET_LOCALES.onshore.value),
      },
      offshore: {
        weight: portfolioModel.offshore_weight,
        portfolio: formatted.filter((item) => item.locale === ASSET_LOCALES.offshore.value).sort((a, b) => a.assetClass.localeCompare(b.assetClass)),
        statistics: await calculateStatistic(ASSET_LOCALES.offshore.value),
      },
    }
  }

  const getHistoryTacticalPosition = async () => {
    const { data: allPortfolioModels } = await supabase
      .from("portfolio_model")
      .select("date, portfolio_model_weight(weight, tactical_position, dim_asset_class(name, locale, dim_benchmark(name, bloomberg_ticker)))")
      .filter("deleted_at", "is", null)
      .order("date", { ascending: false })

    const result = {}

    for (const portfolioModel of allPortfolioModels) {
      const { date, portfolio_model_weight } = portfolioModel

      for (const { dim_asset_class, weight, tactical_position } of portfolio_model_weight) {
        const assetClass = dim_asset_class.name

        if (!result[assetClass])
          result[assetClass] = {
            assetClass,
            weight,
            locale: dim_asset_class.locale,
            ticker: dim_asset_class.dim_benchmark.bloomberg_ticker,
            benchmark: dim_asset_class.dim_benchmark.name,
          }

        result[assetClass][date] = tactical_position
      }
    }

    return Object.values(result).sort((a, b) => {
      const byLocale = a.locale.localeCompare(b.locale)
      if (byLocale !== 0) return byLocale
      return a.assetClass.localeCompare(b.assetClass)
    })
  }

  const newPortfolioModel = async (values) => {
    const portfolioModel = { date: values.date, onshore_weight: values.onshoreWeight / 100, offshore_weight: values.offshoreWeight / 100 }

    const {
      data: [{ id: portfolioModelId }],
    } = await supabase.from("portfolio_model").insert(portfolioModel).select("id")

    const classesGrouped = Object.entries(values).reduce((acc, [key, value]) => {
      if (!key.includes("_")) return acc
      const [assetClassId, field] = key.split("_")

      if (!acc[assetClassId]) acc[assetClassId] = { assetClassId }
      acc[assetClassId][field] = value

      return acc
    }, {})

    const portfolioModelWeight = []

    Object.values(classesGrouped).forEach(({ checked, assetClassId, weight, tacticalPosition }) => {
      if (!checked) return

      portfolioModelWeight.push({
        portfolio_model_id: portfolioModelId,
        asset_class_id: assetClassId,
        weight: weight / 100,
        tactical_position: tacticalPosition,
      })
    })

    await supabase.from("portfolio_model_weight").insert(portfolioModelWeight)
  }

  const deletePortfolioModel = async (id) => {
    await supabase.from("portfolio_model_weight").update({ deleted_at: new Date() }).eq("portfolio_model_id", id)
    await supabase.from("portfolio_model").update({ deleted_at: new Date() }).eq("id", id)
  }

  return { getAvailableDates, getPortfolioModelByDate, getHistoryTacticalPosition, newPortfolioModel, deletePortfolioModel }
}
