import { CheckOutlined, CloseOutlined, DeleteFilled, DeleteOutlined } from "@ant-design/icons"
import { Button, Card, DatePicker, Flex, Form, InputNumber, Select, Table, Tooltip } from "antd"
import dayjs from "dayjs"
import { useEffect, useLayoutEffect } from "react"
import { useModal } from "../../../hooks/context/useModal"
import { MANAGER_SOURCE, TERA_SOURCE } from "../../../hooks/templates/private-investments/useAssumptions"
import { convertQuarterToDate, getNextQuarterAvailable } from "../../../utils/date"
import { colors, spaces } from "../../../utils/styles"
import { AssumptionLabel } from "../../molecules/AssumptionLabel"
import { AssumptionsLegend } from "../../molecules/AssumptionsLegend"

export const AssumptionsForm = ({ assumptions = [], onSubmit = () => {}, onDelete = () => {} }) => {
  const [form] = Form.useForm()
  const modal = useModal()

  useLayoutEffect(() => {
    const tableBody = document.querySelector(".ant-table-content")

    if (tableBody) {
      tableBody.scrollTo({
        left: tableBody.scrollWidth - tableBody.clientWidth,
        behavior: "smooth",
      })
    }
  }, [assumptions])

  const quartersToDisabled = Array.from(
    assumptions.reduce((acc, row) => {
      Object.keys(row).forEach((key) => key !== "type" && acc.add(key))
      return acc
    }, new Set()),
  )

  const secureDelete = (quarter) => {
    modal.confirm({
      title: "Excluir Tarefa",
      content: `Você tem certeza que deseja excluir a assumption do ${quarter}?`,
      okText: "Sim",
      cancelText: "Cancelar",
      onOk: () => onDelete(convertQuarterToDate(quarter)),
    })
  }

  const submitAssumption = (values) => {
    onSubmit({
      capital_call: (values.capital_call || 0) / 100,
      capital_call_source: values.capital_call_source,
      distribution: (values.distribution || 0) / 100,
      distribution_source: values.distribution_source,
      quarter: values.quarter.format("YYYY-MM-01"),
    })
    form.resetFields()
  }

  const getColumnTitle = (key) => {
    if (key === "type") return ""

    return (
      <Flex gap={spaces.space0}>
        <b>{key}</b>
        <Tooltip title="Delete assumption">
          <DeleteOutlined style={{ color: "red" }} onClick={() => secureDelete(key)} />
        </Tooltip>
      </Flex>
    )
  }

  const columns = Object.keys(assumptions?.[0] || []).map((key) => ({
    key,
    title: getColumnTitle(key),
    dataIndex: key,
    render: (value) => (key === "type" ? <b>{value}</b> : <AssumptionLabel value={value?.value} source={value?.source} />),
  }))

  useEffect(() => {
    form.setFieldValue("quarter", dayjs(getNextQuarterAvailable(quartersToDisabled)))
  }, [quartersToDisabled, form])

  return (
    <Flex vertical style={{ gap: spaces.space2 }}>
      <AssumptionsLegend />
      <Table
        columns={columns}
        dataSource={assumptions}
        locale={{ emptyText: "Nenhuma assumption registrada para o ativo" }}
        size="small"
        pagination={false}
        scroll={{ x: "max-content" }}
        style={{ overflowX: "auto" }}
      />
      <Form
        form={form}
        onFinish={submitAssumption}
        onValuesChange={(changedValues, allValues) => {
          if ("capital_call_source" in changedValues && !allValues.distribution_source) {
            form.setFieldValue("distribution_source", changedValues.capital_call_source)
          }
        }}
      >
        <Card size="small" style={{ borderRadius: 8, overflow: "hidden" }}>
          <Card.Grid hoverable={false} style={{ width: 145, padding: spaces.space2, display: "flex", alignItems: "center" }}>
            <b style={{ display: "flex", alignItems: "center" }}>New Assumption</b>
          </Card.Grid>
          <Card.Grid hoverable={false} style={{ width: 890, padding: spaces.space2, display: "flex", gap: spaces.space2 }}>
            <Form.Item
              name="quarter"
              initialValue={dayjs(getNextQuarterAvailable(quartersToDisabled))}
              rules={[{ required: true, message: "Campo obrigatório" }]}
              style={{ marginBottom: 0 }}
            >
              <DatePicker
                picker="quarter"
                placeholder="Quarter"
                style={{ width: 159 }}
                disabledDate={(date) => quartersToDisabled.includes(date.format("YYYY-[Q]Q"))}
                allowClear={false}
              />
            </Form.Item>
            <Form.Item name="capital_call" style={{ marginBottom: 0 }}>
              <InputNumber placeholder="Capital Call" suffix="%" min={0} step={0.1} style={{ width: 159 }} />
            </Form.Item>
            <Form.Item name="capital_call_source" rules={[{ required: true, message: "Campo obrigatório" }]} style={{ marginBottom: 0 }}>
              <Select style={{ width: 159 }} placeholder="Source Info (CC)">
                <Select.Option value={MANAGER_SOURCE}>Manager</Select.Option>
                <Select.Option value={TERA_SOURCE}>TERA</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item name="distribution" style={{ marginBottom: 0 }}>
              <InputNumber placeholder="Distribution" suffix="%" min={0} step={0.1} style={{ width: 159 }} />
            </Form.Item>
            <Form.Item name="distribution_source" rules={[{ required: true, message: "Campo obrigatório" }]} style={{ marginBottom: 0 }}>
              <Select style={{ width: 159 }} placeholder="Source Info (Distr.)">
                <Select.Option value={MANAGER_SOURCE}>Manager</Select.Option>
                <Select.Option value={TERA_SOURCE}>TERA</Select.Option>
              </Select>
            </Form.Item>
          </Card.Grid>
          <Card.Grid hoverable={false} style={{ width: 115, padding: spaces.space2, display: "flex", gap: spaces.space1, justifyContent: "center" }}>
            <Button type="text" icon={<CheckOutlined style={{ fontSize: 20 }} />} style={{ color: colors.green }} htmlType="submit" />
            <Button
              type="text"
              icon={<CloseOutlined style={{ fontSize: 20 }} />}
              style={{ color: colors.primary }}
              onClick={() => form.resetFields()}
            />
          </Card.Grid>
        </Card>
      </Form>
    </Flex>
  )
}
