import * as d3 from "d3"
import { useEffect, useRef } from "react"
import { colors } from "../../../../utils/styles"
import { capitalize } from "../../../../utils/string"

export const LiquidityPlot = ({ data, width = 850, height = 525 }) => {
  const ref = useRef()

  useEffect(() => {
    const margin = { top: 5, right: 20, bottom: 50, left: 70 }
    const chartWidth = width - margin.left - margin.right
    const chartHeight = height - margin.top - margin.bottom

    d3.select(ref.current).selectAll("*").remove()

    const svg = d3
      .select(ref.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr("transform", `translate(${margin.left}, ${margin.top})`)

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.semester))
      .range([0, chartWidth])
      .padding(0.1)

    svg
      .append("g")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-70)")
      .attr("x", -27)
      .attr("dy", 0)

    const negativeData = data.map((d) => ({
      ...d,
      contributionUsd: -d.contributionUsd,
    }))

    // Alteração: definir cores personalizadas para dividendos "PAX US" e "BLACKSTONE CLASS A"
    const dividendKeys = Array.from(new Set(data.flatMap((d) => Object.keys(d.dividends || {})))).sort()

    const dividendColorOrig = d3
      .scaleOrdinal(d3.schemeCategory10)
      .domain(dividendKeys.filter((key) => key !== "PAX US" && key !== "BLACKSTONE CLASS A"))

    const dividendColor = (key) => {
      if (key === "PAX US") return "#2850C4"
      if (key === "BLACKSTONE CLASS A") return "#555555"
      return dividendColorOrig(key)
    }

    const stackedValues = data.map((d) => d.distributionUsd + d3.sum(Object.values(d.dividends || {})))

    const minY = Math.min(
      d3.min(negativeData, (d) => d.contributionUsd),
      d3.min(negativeData, (d) => d.balanceUsd),
    )

    const maxY = Math.max(
      d3.max(stackedValues),
      d3.max(negativeData, (d) => d.balanceUsd),
    )

    let baseScale = (maxY + minY) / 12
    let scale = baseScale
    if (Math.abs(maxY) < 1.2e6 && Math.abs(minY) < 1.2e6) {
      scale = baseScale / 10
    }

    const halfUnitFloor = (val) => scale * Math.floor(val / scale)
    const halfUnitCeil = (val) => scale * Math.ceil(val / scale)

    const roundedMinY = halfUnitFloor(minY)
    const roundedMaxY = halfUnitCeil(maxY)

    const y = d3.scaleLinear().domain([roundedMinY, roundedMaxY]).range([chartHeight, 0])

    let tickFormatFn = (d) => `US$ ${(d / 1e6).toFixed(1)}MM`

    if (Math.abs(maxY) < 1.2e6 && Math.abs(minY) < 1.2e6) tickFormatFn = (d) => `US$ ${(d / 1e5).toFixed(1)}00K`

    const customTicks = d3.range(roundedMinY, roundedMaxY + scale, scale)

    svg.append("g").call(d3.axisLeft(y).tickValues(customTicks).tickFormat(tickFormatFn))

    const yGrid = d3.axisLeft(y).tickValues(customTicks).tickSize(-chartWidth).tickFormat("")

    svg.append("g").attr("class", "grid-y").call(yGrid)

    const xGrid = d3.axisBottom(x).tickSize(-chartHeight).tickFormat("")

    svg
      .append("g")
      .attr("class", "grid-x")
      .attr("transform", `translate(0, ${chartHeight})`)
      .call(xGrid)
      .selectAll("line")
      .attr("stroke", "lightgray")

    svg.select(".grid-y").selectAll("line").attr("stroke", "lightgray")

    svg.append("line").attr("x1", 0).attr("x2", chartWidth).attr("y1", y(0)).attr("y2", y(0)).attr("stroke", "black")

    data.forEach((d) => {
      const xPos = x(d.semester)
      const barWidth = x.bandwidth()
      let cumulative = 0
      const distVal = d.distributionUsd
      let newCumulative = cumulative + distVal
      svg
        .append("rect")
        .attr("class", "stacked-bar distribution")
        .attr("x", xPos)
        .attr("width", barWidth)
        .attr("y", y(newCumulative))
        .attr("height", y(cumulative) - y(newCumulative))
        .attr("fill", colors.green)
      cumulative = newCumulative
      dividendKeys.forEach((key) => {
        const rawVal = d.dividends ? d.dividends[key] || 0 : 0
        if (rawVal === 0) return
        newCumulative = cumulative + rawVal
        svg
          .append("rect")
          .attr("class", "stacked-bar dividend")
          .attr("x", xPos)
          .attr("width", barWidth)
          .attr("y", y(newCumulative))
          .attr("height", y(cumulative) - y(newCumulative))
          .attr("fill", dividendColor(key))
        cumulative = newCumulative
      })
    })

    svg
      .selectAll(".contribution-bar")
      .data(data)
      .enter()
      .append("rect")
      .attr("class", "contribution-bar")
      .attr("x", (d) => x(d.semester))
      .attr("width", x.bandwidth())
      .attr("y", (d, i) => y(0))
      .attr("height", (d, i) => y(negativeData[i].contributionUsd) - y(0))
      .attr("fill", "red")

    svg
      .selectAll(".balance-point")
      .data(data)
      .enter()
      .append("circle")
      .attr("class", "balance-point")
      .attr("cx", (d) => x(d.semester) + x.bandwidth() / 2)
      .attr("cy", (d) => y(d.balanceUsd))
      .attr("r", 4)
      .attr("fill", "black")

    const lineGen = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.balanceUsd))

    svg.append("path").datum(data).attr("d", lineGen).attr("fill", "none").attr("stroke", "black")

    let legendData = [{ label: "Distribution", color: colors.green, shape: "rect" }]

    dividendKeys.forEach((key) => legendData.push({ label: `Dividends (${key})`, color: dividendColor(key), shape: "rect" }))

    legendData.push({ label: "Contribution", color: "red", shape: "rect" })
    legendData.push({ label: "Balance", color: "black", shape: "circle" })

    const legend = svg.append("g").attr("class", "legend").attr("transform", "translate(20,20)")
    const itemHeight = 18

    legendData.forEach((item, index) => {
      const g = legend
        .append("g")
        .attr("class", "legend-item")
        .attr("transform", `translate(0, ${index * itemHeight})`)

      if (item.shape === "rect") g.append("rect").attr("width", 12).attr("height", 12).attr("fill", item.color)
      else if (item.shape === "circle") g.append("circle").attr("cx", 6).attr("cy", 6).attr("r", 6).attr("fill", item.color)

      g.append("text").attr("x", 18).attr("y", 12).text(item.label).style("font-size", "12px").attr("fill", "#000")
    })

    const legendBBox = legend.node().getBBox()

    legend
      .insert("rect", ":first-child")
      .attr("x", legendBBox.x - 5)
      .attr("y", legendBBox.y - 5)
      .attr("width", legendBBox.width + 10)
      .attr("height", legendBBox.height + 10)
      .attr("fill", "white")
      .attr("stroke", "#000")
      .attr("stroke-width", 0.5)
  }, [data])

  return <svg ref={ref} />
}
