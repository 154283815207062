import { filesQuantFormats } from "../../../utils/utils"
import { StaticFileViewer } from "../../molecules/viewers/StaticFileViewer"

export const TacticalSignal = () => {
  const files = [
    {
      title: "Momentum - Core Bonds",
      folder: "tactical-signals/momentum-core-bonds",
      format: filesQuantFormats.PDF,
    },
    {
      title: "Momentum - PM & Benchmarks",
      folder: "tactical-signals/momentum-pm-benchmarks",
      format: filesQuantFormats.PDF,
    },
    {
      title: "Other Signals - Fixed Income",
      folder: "tactical-signals/statistics-fixed-income",
      format: filesQuantFormats.PDF,
    },
    {
    title: "Correlation and Volatility",
    folder: "tactical-signals/correlation-volatility",
    format: filesQuantFormats.PDF,
  },

    
  ]

  return <StaticFileViewer files={files} />
}
