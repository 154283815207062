export const ShareService = (supabase) => {
  const getPAXSharesByCustomer = async (customerId) => {
    const { data: paxViaPHL } = await supabase
      .from("phl_share")
      .select("pax_quantity")
      .eq("customer_id", customerId)
      .filter("deleted_at", "is", null)
      .order("created_at", { ascending: false })
      .limit(1)

    const paxSharesViaPHL = paxViaPHL[0]?.pax_quantity || 0

    const { data } = await supabase
      .from("fact_asset_allocation")
      .select("quantity")
      .eq("dim_customer_id", customerId)
      .eq("dim_asset_id", 7253)
      .order("date", { ascending: false })
      .limit(1)

    const paxSharesDirectly = data[0]?.quantity || 0

    return paxSharesViaPHL + paxSharesDirectly
  }

  const getGenericSharesByCustomer = async (customerId, assetId) => {
    const { data } = await supabase
      .from("fact_asset_allocation")
      .select("quantity")
      .eq("dim_customer_id", customerId)
      .eq("dim_asset_id", assetId)
      .order("date", { ascending: false })
      .limit(1)

    return data[0]?.quantity || 0
  }

  return { getPAXSharesByCustomer, getGenericSharesByCustomer }
}
