import { Flex, Tag } from "antd"
import { colors } from "../../utils/styles"

export const AssumptionsLegend = () => (
  <Flex>
    <Tag color={colors.assumptionTera} style={{ color: "black" }}>
      TERA Assumption
    </Tag>
    <Tag color={colors.assumptionManager} style={{ color: "black" }}>
      Manager Assumption
    </Tag>
  </Flex>
)
