import { LoadingOutlined } from "@ant-design/icons"
import { Flex, Spin } from "antd"
import { useEffect, useState } from "react"
import { spaces } from "../../../utils/styles"

export const Loader = ({ fontSize = 50 } = {}) => {
  return (
    <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}>
      <Spin indicator={<LoadingOutlined style={{ fontSize }} spin />} />
    </div>
  )
}

export const LoaderFullScreen = ({ showRandomFacts = false }) => {
  const [randomFact, setRandomFact] = useState(null)

  useEffect(() => {
    if (!showRandomFacts) return () => {}

    fetchRandomFact()
    const interval = setInterval(fetchRandomFact, 6000)
    return () => clearInterval(interval)
  }, [])

  const fetchRandomFact = async () => {
    const response = await fetch("https://uselessfacts.jsph.pl/random.json?language=en")
    const data = await response.json()
    setRandomFact(data.text)
  }

  return (
    <Flex justify="center" align="center" style={{ height: "85vh", width: "100vw", padding: spaces.space4 }}>
      <Flex style={{ flexDirection: "column", gap: spaces.space2 }}>
        <Loader />
        {showRandomFacts && <h3>{randomFact}</h3>}
      </Flex>
    </Flex>
  )
}
