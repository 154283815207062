import { useEffect, useState } from "react"
import { tables } from "../utils/utils"
import { useSupabase } from "./context/useSupabase"

const aggregateCustomers = (data) => {
  const dates = {}
  const totals = {}

  const aggregated = data.reduce((acc, { strategy, customer, target, class_name, date, amount }) => {
    const groupBy = `${strategy}-${class_name}`
    if (!acc[groupBy]) acc[groupBy] = { strategy, class_name }
    acc[groupBy][customer] = target

    dates[customer] = date.substring(0, 10)

    if (!totals[customer]) totals[customer] = 0
    totals[customer] += amount

    return acc
  }, {})

  return { excel: Object.values(aggregated), dates, totals }
}

export const useIPS = ({ locale, selectedCustomer }) => {
  const supbase = useSupabase()
  const [ips, setIPS] = useState()
  const [dates, setDates] = useState()
  const [totalOnshore, setTotalOnshore] = useState()
  const [totalOffshore, setTotalOffshore] = useState()
  const [totals, setTotals] = useState()
  const [excelAllClients, setExcelAllClients] = useState()
  const [customers, setCustomers] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const generateIPSFull = (ips) => {
    let totalOnshore = 0
    let totalOffshore = 0

    ips.forEach((row) => {
      if (row.class_name.includes("BZ")) totalOnshore += row.amount || 0
      else totalOffshore += row.amount || 0
    })

    const totalGlobal = totalOnshore + totalOffshore

    const globalTargetMap = ips.reduce((acc, row) => {
      const key = row.class_name.replace(" BZ", "")

      if (!acc[key])
        acc[key] = {
          customer: selectedCustomer,
          class_name: key,
          strategy: row.strategy,
          amount: 0,
          current: 0,
          target: 0,
        }

      const reference = row.class_name.includes("BZ") ? totalOnshore : totalOffshore

      acc[key].amount += row.amount || 0
      acc[key].target += (reference * (row.target || 0)) / totalGlobal
      acc[key].current += (row.amount || 0) / totalGlobal

      return acc
    }, {})

    return [Object.values(globalTargetMap).sort((a, b) => a.class_name.localeCompare(b.class_name)), totalOnshore, totalOffshore]
  }

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)

      const { data } = await supbase.from(`view_ips_${locale === "all" ? "full" : locale}`).select("*")
      const { excel, dates, totals } = aggregateCustomers(data)

      setIsLoading(false)
      setDates(dates)
      setExcelAllClients(excel)
      setTotals(totals)
      setCustomers(Object.keys(dates).sort())

      const customerIPS = data.filter((row) => row.customer === selectedCustomer)

      if (locale !== "all") return setIPS(customerIPS)

      const [ipsFull, totalOnshore, totalOffshore] = generateIPSFull(customerIPS)

      if (!ipsFull.length) return () => {}

      setTotalOnshore(totalOnshore)
      setTotalOffshore(totalOffshore)
      setIPS(ipsFull?.sort((a, b) => a.class_name?.localeCompare(b.class_name)))
    }

    fetch()
  }, [locale, selectedCustomer])

  return { isLoading, ips, customers, dates, excelAllClients, totals }
}

export const useExpectedReturn = () => {
  const [reload, setReload] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [expectedReturn, setExpectedReturn] = useState([])
  const [date, setDate] = useState()
  const [availableDates, setAvailableDates] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const supabase = useSupabase()

  const forceReload = () => setReload((prev) => prev + 1)

  const insertExpectedReturn = (data) => supabase.from(tables.FACT_EXPECTED_RETURN).insert(data).then(forceReload)

  useEffect(() => {
    if (!date && availableDates.length > 0) setDate(availableDates[0])
  }, [availableDates])

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)

      const { data: dates } = await supabase.from(tables.FACT_EXPECTED_RETURN).select("date").order("date", { ascending: false })
      setAvailableDates(Array.from(dates.reduce((acc, { date }) => acc.add(date), new Set())))
      setExpectedReturn((await supabase.from(tables.FACT_EXPECTED_RETURN).select("*").eq("date", date)).data || [])

      setIsLoading(false)
    }

    fetch()
  }, [reload, date])

  return { expectedReturn, availableDates, showModal, isLoading, date, setShowModal, setDate, insertExpectedReturn }
}
