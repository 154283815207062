import { Flex, Select, Tag } from "antd"
import { use, useContext } from "react"
import { GlobalContext } from "../../../context/GlobalContext"
import { spaces } from "../../../utils/styles"
import { classColors } from "../../../utils/utils"

export const AssetClassSelect = ({ setAssetClass, defaultValue, value, disabled, useOnlyParents = true, width = 175, showLabel = true, locale = null }) => {
  const contextClasses = useContext(GlobalContext).classes

  const classes = contextClasses.filter((assetClass) => {
    if (useOnlyParents && assetClass.parent) return false
    if (locale && assetClass.locale !== locale) return false
    return true
  })

  const update = (value) => {
    if (value === defaultValue) setAssetClass(null)
    else setAssetClass(value)
  }

  return (
    <Flex gap={spaces.space1} style={{ alignItems: "center" }}>
      {showLabel && <b>Classe:</b>}
      <Select
        showSearch
        style={{ width }}
        onChange={update}
        value={value}
        disabled={disabled}
        optionFilterProp="children"
        filterOption={(input, option) => option.children?.props?.children?.toLowerCase().includes(input.toLowerCase())}
        notFoundContent="Classe não encontrada"
      >
        {defaultValue && (
          <Select.Option value={defaultValue} key={defaultValue}>
            {defaultValue}
          </Select.Option>
        )}
        {classes.map((assetClass) => (
          <Select.Option key={assetClass.id} value={assetClass.id}>
            <Tag color={classColors[assetClass.name]}>{assetClass.name}</Tag>
          </Select.Option>
        ))}
      </Select>
    </Flex>
  )
}
