import { DeleteOutlined, SaveOutlined } from "@ant-design/icons"
import { Alert, Button, Col, DatePicker, Form, Input, Modal, Radio, Row } from "antd"
import dayjs from "dayjs"
import { useContext, useEffect, useRef, useState } from "react"
import { AttachmentProvider } from "../../../context/AttachmentsContext"
import { CommentsProvider } from "../../../context/CommentsContext"
import { GlobalContext } from "../../../context/GlobalContext"
import { TaskContext } from "../../../context/TaskContext"
import { useAuth } from "../../../hooks/context/useAuth"
import { spaces } from "../../../utils/styles"
import { tables } from "../../../utils/utils"
import { AssetSelect } from "../../atoms/select/AssetSelect"
import { CustomerOrFundSelect, MODES } from "../../atoms/select/CustomerOrFundSelect"
import { Attachments } from "../../organisms/Attachments"
import { Comments } from "../../organisms/Comments"
import { TextRich } from "../inputs/TextRich"

export const TaskModal = ({ onSubmit, onDelete }) => {
  const { selectedTask, isToOpenModal, closeModal, setSelectedTask } = useContext(TaskContext)
  const { modal } = useContext(GlobalContext)
  const [mode, setMode] = useState(MODES.CUSTOMER)
  const initialized = useRef(false)
  const [form] = Form.useForm()
  const { isManagementTeam } = useAuth()

  useEffect(() => {
    if (initialized.current) form.setFieldsValue({ customerOrFund: null })
    else initialized.current = true
  }, [mode])

  useEffect(() => {
    if (!selectedTask) {
      form.resetFields()
      return () => {}
    }

    if (selectedTask.customerId) setMode(MODES.CUSTOMER)
    if (selectedTask.fundId) setMode(MODES.FUND)

    form.setFieldsValue({
      title: selectedTask.title,
      description: selectedTask.description,
      expired_at: selectedTask.expired_at ? dayjs(selectedTask.expired_at) : null,
      customerOrFund: selectedTask.customerId || selectedTask.fundId,
      assetId: selectedTask.assetId,
    })
  }, [selectedTask])

  const MARGIN_FIELDS = { marginBottom: spaces.space1 }

  const onOk = () => {
    closeModal()

    const result = { ...form.getFieldsValue(), fundId: null, customerId: null }

    if (mode === MODES.CUSTOMER && result.customerOrFund) result.customerId = result.customerOrFund
    if (mode === MODES.FUND && result.customerOrFund) result.fundId = result.customerOrFund
    if (selectedTask) result.id = selectedTask.id

    delete result.customerOrFund

    if (selectedTask) onSubmit(result)
    else onSubmit(result)

    setSelectedTask(null)
    form.resetFields()
  }

  const secureDelete = () => {
    modal.confirm({
      title: "Excluir Tarefa",
      content: "Você tem certeza que deseja excluir essa tarefa?",
      okText: "Sim",
      cancelText: "Cancelar",
      onOk: () => {
        closeModal()
        onDelete()
        setSelectedTask(null)
        form.resetFields()
      },
    })
  }

  const modeRadio = (
    <Radio.Group value={mode} onChange={(e) => setMode(e.target.value)} style={{ width: "-webkit-fill-available" }}>
      <Radio value={MODES.CUSTOMER}>Cliente</Radio>
      <Radio value={MODES.FUND}>Fundo</Radio>
    </Radio.Group>
  )

  return (
    <Modal
      title={selectedTask ? "Editar Tarefa" : "Nova Tarefa"}
      open={isToOpenModal}
      onCancel={closeModal}
      destroyOnClose={true}
      styles={{ body: { marginTop: spaces.space2 } }}
      cancelText="Cancelar"
      width={1200}
      footer={[
        selectedTask && (
          <Button key="delete" icon={<DeleteOutlined />} onClick={secureDelete} disabled={!isManagementTeam}>
            Excluir
          </Button>
        ),
        <Button key="submit" type="primary" onClick={onOk} icon={<SaveOutlined />} disabled={!isManagementTeam}>
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          name="title"
          rules={[{ required: true }]}
          tooltip="Esse campo será utilizado futuramente para preencher os itens da Ata de Comitê. Portanto, pense no texto de uma forma que se encaixe no nosso formato de documento."
          style={MARGIN_FIELDS}
        >
          <Input placeholder="Digite o título da tarefa" />
        </Form.Item>
        <Form.Item name="description" style={MARGIN_FIELDS}>
          <TextRich value={form.getFieldValue("description")} setValue={(value) => form.setFieldsValue({ description: value })} height={300} />
        </Form.Item>
        <Row gutter={spaces.space2} style={{ marginTop: spaces.space3 }}>
          <Col span={10} style={{ display: "flex", alignItems: "center" }} id="teste">
            <Form.Item name="customerOrFund" label={modeRadio} style={{ ...MARGIN_FIELDS, width: "-webkit-fill-available" }}>
              <CustomerOrFundSelect
                hideLabel={true}
                hasDefaultOption={false}
                mode={mode}
                setValue={(value) => form.setFieldsValue({ customerOrFund: value })}
                value={form.getFieldValue("customerOrFund")}
                width="-webkit-fill-available"
              />
            </Form.Item>
          </Col>

          <Col span={10}>
            <Form.Item name="assetId" label="Ativo" style={MARGIN_FIELDS}>
              <AssetSelect
                setValue={(value) => form.setFieldsValue({ assetId: value })}
                value={form.getFieldValue("assetId")}
                style={{ width: "-webkit-fill-available" }}
              />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item name="expired_at" label="Prazo Estimado" style={MARGIN_FIELDS}>
              <DatePicker placeholder="" style={{ width: "-webkit-fill-available" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {!selectedTask && (
        <Alert message="Salve a tarefa para habilitar os comentários e anexos." type="warning" showIcon style={{ marginTop: spaces.space2 }} />
      )}

      {selectedTask && (
        <>
          <AttachmentProvider
            table={tables.MANAGEMENT_TASK_ATTACHMENT}
            parentColumn={"dim_management_task_id"}
            parentId={selectedTask?.id}
            folder={`management_task/${selectedTask?.id}`}
          >
            <Attachments style={{ marginTop: spaces.space2 }} />
          </AttachmentProvider>

          <CommentsProvider table={tables.FACT_MANAGEMENT_TASK_COMMENT} parentColumn={"dim_management_task_id"} parentId={selectedTask?.id}>
            <Comments style={{ marginTop: spaces.space3 }} />
          </CommentsProvider>
        </>
      )}
    </Modal>
  )
}
