import {
  DeleteOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  PlusOutlined,
} from "@ant-design/icons"
import { Button, Card, Empty, Flex, Modal, Tooltip, Upload } from "antd"
import { useAttachment } from "../../hooks/components/useAttachment"
import { useAuth } from "../../hooks/context/useAuth"
import { colors, spaces } from "../../utils/styles"
import { Loader } from "../atoms/animations/Loader"
import { Ellipsis } from "../atoms/formatters/Ellipsis"

export const Attachments = ({ style = {} }) => {
  const { attachments, isLoading, addAttachment, deleteAttachment, downloadAttachment } = useAttachment()
  const { isManagementTeam } = useAuth()

  const ICON_SIZE = 24

  const secureDelete = (attachment) => {
    Modal.confirm({
      title: "Você tem certeza que deseja deletar esse anexo?",
      content: "Esta ação não pode ser desfeita.",
      okText: "Excluir",
      okButtonProps: { style: { backgroundColor: colors.primary } },
      cancelText: "Cancelar",
      onOk: () => deleteAttachment(attachment),
    })
  }

  const getFileExtension = (fileName) => {
    const parts = fileName.split(".")
    return parts.length > 1 ? parts[parts.length - 1].toLowerCase() : ""
  }

  const iconRender = (file) => {
    const ext = getFileExtension(file.filename)

    if (ext === "pdf") return <FilePdfOutlined style={{ fontSize: ICON_SIZE, color: "#d9534f" }} />
    if (ext === "doc" || ext === "docx") return <FileWordOutlined style={{ fontSize: ICON_SIZE, color: "#0275d8" }} />
    if (ext === "xls" || ext === "xlsx") return <FileExcelOutlined style={{ fontSize: ICON_SIZE, color: "#5cb85c" }} />
    if (["png", "jpg", "jpeg"].includes(ext)) return <FileImageOutlined style={{ fontSize: ICON_SIZE, color: "#f0ad4e" }} />

    return <FileOutlined style={{ fontSize: ICON_SIZE }} />
  }

  if (isLoading) return <Loader />

  return (
    <Flex style={{ gap: spaces.space1, overflowX: "scroll", paddingBottom: spaces.space1, ...style }}>
      <Upload beforeUpload={(file) => addAttachment(file)} showUploadList={false}>
        <Button type="dashed" icon={<PlusOutlined />} onClick={() => {}} style={{ height: 100 }} disabled={!isManagementTeam}>
          Novo anexo
        </Button>
      </Upload>
      {!attachments?.length && (
        <Card size="small" style={{ width: "-webkit-fill-available" }}>
          <Empty image={Empty.PRESENTED_IMAGE_DEFAULT} description="Nenhum anexo registrado" styles={{ image: { height: 45 } }} />
        </Card>
      )}
      {attachments?.map((attachment, index) => (
        <Card size="small" style={{ minWidth: 150 }} key={index}>
          <Flex vertical style={{ alignItems: "center", gap: spaces.space1 }}>
            {iconRender(attachment)}
            <Ellipsis>{attachment.filename}</Ellipsis>
            <Flex gap={spaces.space0}>
              <Tooltip title="Download">
                <DownloadOutlined style={{ cursor: "pointer" }} onClick={() => downloadAttachment(attachment.filename)} />
              </Tooltip>
              <Tooltip title="Excluir">
                <DeleteOutlined style={{ cursor: "pointer" }} onClick={() => secureDelete(attachment)} />
              </Tooltip>
              {/* <Tooltip title="Copiar link">
                <CopyOutlined style={{ cursor: "pointer" }} />
              </Tooltip> */}
            </Flex>
          </Flex>
        </Card>
      ))}
    </Flex>
  )
}
