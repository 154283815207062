import { CaretDownOutlined } from "@ant-design/icons"
import { Flex } from "antd"
import { spaces } from "../../utils/styles"
import { MoneyLabel } from "../atoms/formatters/MoneyLabel"

export const OutflowLabel = ({ value, currency = "USD" }) => {
  return (
    <Flex style={{ justifyContent: "center", gap: spaces.space1, color: "red" }}>
      <CaretDownOutlined style={{ color: "red" }} />
      <MoneyLabel value={value} currency={currency} />
    </Flex>
  )
}
