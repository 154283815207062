import { DatePicker, Flex, Radio } from "antd"
import dayjs from "dayjs"
import { BRAZILIAN_CREDIT_BONDS_TYPE, useBrazilianCreditBonds } from "../../../hooks/useBrazilianCreditBonds"
import { spaces } from "../../../utils/styles"
import { BrazilianCreditBondsPlot } from "../../molecules/charts/BrazilianCreditBondsPlot"

export const BrazilianCreditBonds = () => {
  const { date, setDate, filterType, setFilterType, availableDates, result } = useBrazilianCreditBonds()

  return (
    <Flex vertical style={{ padding: spaces.space1 }}>
      <Flex style={{ alignItems: "center", gap: spaces.space2 }}>
        <DatePicker
          onChange={(date) => setDate(date.format("YYYY-MM-DD"))}
          value={dayjs(date)}
          disabledDate={(current) => !availableDates.includes(current.format("YYYY-MM-DD"))}
          allowClear={false}
        />
        <Radio.Group defaultValue={filterType} onChange={(e) => setFilterType(e.target.value)}>
          {BRAZILIAN_CREDIT_BONDS_TYPE.map((item) => (
            <Radio value={item}>{item.label}</Radio>
          ))}
        </Radio.Group>
      </Flex>
      <Flex style={{ marginLeft: spaces.space1 }}>
        <BrazilianCreditBondsPlot data={result} legend={filterType.legend} />
      </Flex>
    </Flex>
  )
}
