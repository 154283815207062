import { ConfigProvider } from "antd"
import "katex/dist/katex.min.css"
import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./components/pages/App"
import "./index.css"
import { colors } from "./utils/styles"

const designSystem = {
  colorPrimary: colors.primary,
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <ConfigProvider theme={{ token: designSystem }}>
    <App />
  </ConfigProvider>,
)
