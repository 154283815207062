import { createContext, useMemo } from "react"
import { useSupabase } from "../hooks/context/useSupabase"
import { AssetService } from "../service/AssetService"
import { PrivateInvestmentsService } from "../service/PrivateInvestmentsService"
import { FactPositionsService } from "../service/data-warehouse/FactPositionsService"
import { FactPrivateInvestmentsAnalysisService } from "../service/data-warehouse/FactPrivateInvestmentsAnalysisService"
import { FactPrivateInvestmentsCashflowEstimated } from "../service/data-warehouse/FactPrivateInvestmentsCashflowEstimated"
import { ShareService } from "../service/ShareService"
import { ExpectedDividendPerShareService } from "../service/ExpectedDividendPerShareService"
import { DimCustomerService } from "../service/data-warehouse/DimCustomerService"

export const ServicesContext = createContext({
  privateInvestmentsService: null,
  assetService: null,
  shareService: null,
  dimCustomerService: null,
  expectedDividendPerShareService: null,
  factPrivateInvestmentsAnalysisService: null,
  factPositions: null,
  factPrivateInvestmentsCashflowEstimated: null,
})

export const ServicesProvider = ({ children }) => {
  const supabase = useSupabase()

  const servicesMemoized = {
    privateInvestmentsService: useMemo(() => PrivateInvestmentsService(supabase), [supabase]),
    assetService: useMemo(() => AssetService(supabase), [supabase]),
    shareService: useMemo(() => ShareService(supabase), [supabase]),
    dimCustomerService: useMemo(() => DimCustomerService(supabase), [supabase]),
    expectedDividendPerShareService: useMemo(() => ExpectedDividendPerShareService(supabase), [supabase]),
    factPrivateInvestmentsAnalysisService: useMemo(() => FactPrivateInvestmentsAnalysisService(supabase), [supabase]),
    factPrivateInvestmentsCashflowEstimated: useMemo(() => FactPrivateInvestmentsCashflowEstimated(supabase), [supabase]),
    factPositions: useMemo(() => FactPositionsService(supabase), [supabase]),
  }

  return <ServicesContext.Provider value={servicesMemoized}>{children}</ServicesContext.Provider>
}
