import { CheckOutlined, CloseOutlined } from "@ant-design/icons"
import { InputNumber } from "antd"
import React, { useState } from "react"
import { spaces } from "../../../utils/styles"
import { round } from "../../../utils/utils"

export const EditableNumber = ({ initialValue, onChange, prefix = "", suffix = "", step = 0.1, styleInput, styleText }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(initialValue)
  const [tempValue, setTempValue] = useState(initialValue)

  const handleTextClick = () => setIsEditing(true)

  const handleInputChange = (val) => setTempValue(val)

  const handleConfirm = () => {
    setValue(tempValue || 0)
    setIsEditing(false)
    if (onChange) onChange(tempValue || 0)
  }

  const handleCancel = () => {
    setTempValue(value)
    setIsEditing(false)
  }

  return (
    <div>
      {!isEditing ? (
        <div onClick={handleTextClick} style={{ ...styleText, width: styleInput.width }} className="editable-cell-value-wrap">
          {value || value === 0 ? `${prefix} ${round(value || 0).toFixed(2)} ${suffix}` : "-"}
        </div>
      ) : (
        <div style={{ display: "flex", alignItems: "center" }}>
          <InputNumber
            value={tempValue}
            onChange={handleInputChange}
            onPressEnter={handleConfirm}
            min={0}
            style={styleInput}
            suffix={suffix}
            prefix={prefix}
            step={step}
            allowClear={true}
          />
          <CheckOutlined
            onClick={handleConfirm}
            style={{ color: "green", marginLeft: spaces.space1, marginRight: spaces.space1, cursor: "pointer" }}
          />
          <CloseOutlined onClick={handleCancel} style={{ marginRight: spaces.space1, color: "red", cursor: "pointer" }} />
        </div>
      )}
    </div>
  )
}
