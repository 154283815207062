import { Card, DatePicker, Empty, Flex, Modal, Progress, Select, Tag } from "antd"
import dayjs from "dayjs"
import { AttachmentProvider } from "../../../context/AttachmentsContext"
import { CommentsProvider } from "../../../context/CommentsContext"
import { usePrivateInvestmentInfo } from "../../../hooks/templates/private-investments/usePrivateInvestmentInfo"
import { spaces } from "../../../utils/styles"
import { tables } from "../../../utils/utils"
import { Attachments } from "../../organisms/Attachments"
import { Comments } from "../../organisms/Comments"
import { AssumptionsForm } from "../../organisms/forms/AssumptionsForm"

export const EditAssumptionsModal = ({ idFund = null, onClose = () => {} }) => {
  const {
    assumptions,
    funds,
    selectedFund,
    info,
    performedContribution,
    performedDistribution,
    estimatedContribution,
    estimatedDistribution,
    setSelectedFund,
    updateVintage,
    updateFundConclusion,
    updateEndOfInvestmentPeriod,
    updateMandate,
    newAssumption,
    deleteAssumption,
  } = usePrivateInvestmentInfo(idFund)

  const percentCardStyle = {
    width: 287,
    paddingLeft: spaces.space1,
    paddingRight: spaces.space1,
    paddingTop: spaces.space2,
    paddingBottom: spaces.space2,
  }

  return (
    <Modal
      open
      onCancel={onClose}
      width={1200}
      title={
        <Flex style={{ gap: spaces.space6, alignItems: "center", fontSize: 14 }}>
          <b>Fund: </b>
          <Select
            options={funds.map((fund) => ({ label: fund.name, value: fund.id }))}
            style={{ width: 400 }}
            value={selectedFund}
            onChange={setSelectedFund}
          />
        </Flex>
      }
      footer={null}
    >
      <Flex vertical style={{ paddingTop: spaces.space0, paddingBottom: spaces.space2, gap: spaces.space2 }}>
        {!selectedFund && <Empty description="Select a fund to view the information" />}
        {selectedFund && (
          <>
            <Flex style={{ gap: spaces.space3, alignItems: "center", marginTop: spaces.space0 }}>
              <b>Mandate:</b>
              <Select style={{ width: 200 }} value={info?.mandate} onChange={(mandate) => updateMandate(mandate)}>
                <Select.Option value="TERA">TERA</Select.Option>
                <Select.Option value="Cambridge Associates">Cambridge Associates</Select.Option>
                <Select.Option value="Pátria">Pátria</Select.Option>
              </Select>
              <b>Vintage: </b>
              <DatePicker.YearPicker
                picker="year"
                placeholder="Ano"
                style={{ width: 100 }}
                allowClear={false}
                value={info?.vintage && dayjs(`01-01-${info?.vintage}`)}
                onChange={(year) => updateVintage(dayjs(year).format("YYYY"))}
              />
              <b>Expected End of Investment Period: </b>
              <DatePicker
                picker="month"
                style={{ width: 110 }}
                placeholder="Month"
                allowClear={false}
                value={info?.end_of_investment_period && dayjs(info?.end_of_investment_period)}
                onChange={(quarter) => updateEndOfInvestmentPeriod(dayjs(quarter).format("YYYY-MM-01"))}
              />
              <b>Expected Fund Conclusion: </b>
              <DatePicker
                picker="month"
                style={{ width: 110 }}
                placeholder="Month"
                allowClear={false}
                value={info?.fund_conclusion && dayjs(info?.fund_conclusion)}
                onChange={(quarter) => updateFundConclusion(dayjs(quarter).format("YYYY-MM-01"))}
              />
            </Flex>
            <Flex vertical style={{ width: "100%", gap: spaces.space1, marginTop: spaces.space1 }}>
              <Card style={{ marginBottom: spaces.space2 }} size="small">
                <Card.Grid hoverable={false} style={percentCardStyle}>
                  <Flex style={{ direction: "column" }}>
                    <Tag color="red">Previous CC / Commit.</Tag>
                    <Progress
                      percent={performedContribution}
                      format={() => <p style={{ color: "black" }}>{performedContribution.toFixed(0)}%</p>}
                      strokeColor={"red"}
                    />
                  </Flex>
                </Card.Grid>
                <Card.Grid hoverable={false} style={percentCardStyle}>
                  <Flex style={{ direction: "column" }}>
                    <Tag color="red">Expected New CC / Commit.</Tag>
                    <Progress
                      percent={estimatedContribution}
                      format={() => <p style={{ color: "black" }}>{estimatedContribution.toFixed(0)}%</p>}
                      strokeColor={"red"}
                    />
                  </Flex>
                </Card.Grid>
                <Card.Grid hoverable={false} style={percentCardStyle}>
                  <Flex style={{ direction: "column" }}>
                    <Tag color="green">Previous Dist. / Commit.</Tag>
                    <Progress
                      percent={performedDistribution}
                      format={() => <p style={{ color: "black" }}>{performedDistribution.toFixed(0)}%</p>}
                      strokeColor={"green"}
                    />
                  </Flex>
                </Card.Grid>
                <Card.Grid hoverable={false} style={percentCardStyle}>
                  <Flex style={{ direction: "column" }}>
                    <Tag color="green">Expected New Dist. / Commit.</Tag>
                    <Progress
                      percent={estimatedDistribution}
                      format={() => <p style={{ color: "black" }}>{estimatedDistribution.toFixed(0)}%</p>}
                      strokeColor={"green"}
                    />
                  </Flex>
                </Card.Grid>
              </Card>
              <AssumptionsForm
                assumptions={assumptions}
                onSubmit={(values) => {
                  newAssumption(values)
                }}
                onDelete={(quarter) => {
                  deleteAssumption(quarter)
                }}
              />
              <AttachmentProvider
                table={tables.PIPELINE_ATTACHMENT}
                parentId={selectedFund}
                parentColumn={"dim_pipeline_asset"}
                folder={`pipeline/${selectedFund}`}
              >
                <Attachments style={{ width: "-webkit-fill-available" }} />
              </AttachmentProvider>
              <CommentsProvider table={tables.FACT_MANAGEMENT_TASK_COMMENT} parentColumn={"dim_management_task_id"} parentId={selectedFund}>
                <Comments style={{ marginTop: spaces.space3 }} />
              </CommentsProvider>
            </Flex>
          </>
        )}
      </Flex>
    </Modal>
  )
}
