import { useEffect, useState } from "react"
import { distinctObjects } from "../../../utils/array"
import { useService } from "../../context/useService"
import { useSession } from "../../context/useSupabase"
import { useToast } from "../../context/useToast"
import { useLoading } from "../../utils/useLoading"

export const useProjections = () => {
  const [data, setData] = useState([])
  const [customer, setCustomer] = useState(null)
  const [asset, setAsset] = useState(null)
  const [assets, setAssets] = useState(null)
  const [lastProjectionDate, setProjectionDate] = useState(null)
  const [lastAssumptionUpdate, setLastAssumptionUpdate] = useState(null)
  const { factPrivateInvestmentsAnalysisService, privateInvestmentsService } = useService()
  const session = useSession()
  const { reload, refresh, setIsLoading, isLoading } = useLoading()
  const toast = useToast()

  const refreshAssetList = async () => {
    if (!customer) return

    const items = await factPrivateInvestmentsAnalysisService.getAll({ dimCustomerId: customer })
    const uniqueAssets = distinctObjects(items.map((item) => item.dimAsset))
    const selectOptions = uniqueAssets.map((item) => ({ value: item.id, label: item.name }))

    setAssets(selectOptions.sort((a, b) => a.label.localeCompare(b.label)))

    if (selectOptions.length) setAsset(selectOptions[0].value)
    else setAsset(null)
  }

  const refreshLastAssumptionInserted = async () => {
    setProjectionDate(await factPrivateInvestmentsAnalysisService.getLastUpdate())
    setLastAssumptionUpdate(await privateInvestmentsService.getLastAssumptionUpdateDate())
    console.log("lastAssumptionInserted", lastAssumptionUpdate)
  }

  useEffect(() => {
    refreshLastAssumptionInserted()
  }, [])

  useEffect(() => {
    if (asset) factPrivateInvestmentsAnalysisService.getAll({ dimCustomerId: customer, dimAssetId: asset }).then(setData)
  }, [asset, reload])

  useEffect(() => {
    refreshAssetList()
  }, [customer, reload])

  const isNotUpdatedAnalysis = lastAssumptionUpdate && lastProjectionDate && lastAssumptionUpdate > lastProjectionDate

  const update = async () => {
    setIsLoading(true)
    toast.info({ message: "Updating projections... Read some random facts while you wait!" })
    await privateInvestmentsService.refreshProjections(session.access_token)

    await new Promise((resolve) => setTimeout(resolve, 45000))

    toast.success({ message: "Done!" })
    refresh()
    setIsLoading(false)
  }

  return { customer, setCustomer, assets, asset, setAsset, data, isNotUpdatedAnalysis, update, isLoading, refreshLastAssumptionInserted }
}
