import { ExclamationCircleFilled } from "@ant-design/icons";
import { Flex, Tooltip } from "antd";
import { spaces } from "../../utils/styles";

export const ClassAndBenchmark = ({ className, benchmarkName, bloombergTicker }) => (
    <Flex gap={spaces.space0}>
        {className}
        <Tooltip title={`Benchmark: ${benchmarkName} (${bloombergTicker || 'Sem ticker Bloomberg'})`}>
            <ExclamationCircleFilled style={{ color: "red" }} />
        </Tooltip>
    </Flex>
)