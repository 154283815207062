import { EditOutlined } from "@ant-design/icons"
import { Button, Flex, Progress, Table, Tag, Tooltip } from "antd"
import { useProjections } from "../../../hooks/templates/private-investments/useProjections"
import { useAssumptions } from "../../../hooks/templates/private-investments/useAssumptions"
import { useViewport } from "../../../hooks/utils/useViewport"
import { formatDate } from "../../../utils/date"
import { spaces } from "../../../utils/styles"
import { LoaderFullScreen } from "../../atoms/animations/Loader"
import { Ellipsis } from "../../atoms/formatters/Ellipsis"
import { SimpleRoundedValueLabel } from "../../atoms/formatters/SimpleRoundedValueLabel"
import { AssumptionLabel } from "../../molecules/AssumptionLabel"
import { AssumptionsLegend } from "../../molecules/AssumptionsLegend"
import { EditAssumptionsModal } from "../../molecules/modals/EditAssumptionsModal"
import { NewAssumptionsDetected } from "../../molecules/NewAssumptionsDetected"
import { useAuth } from "../../../hooks/context/useAuth"

export const Assumptions = () => {
  const { data, openModal, setOpenModal, fundToEdit, setFundToEdit, quarters, refresh, isLoading } = useAssumptions()
  const { isNotUpdatedAnalysis, isLoading: isLoadingProjections, update, refreshLastAssumptionInserted } = useProjections()
  const { height } = useViewport()
  const { isManagementTeam } = useAuth()

  const mergeCapitalCallAndDistributionLinesFromSameFund = (_, index) => (index % 2 === 0 ? { rowSpan: 2 } : { rowSpan: 0 })

  const columns = [
    {
      title: "Fund",
      dataIndex: "fund",
      width: 250,
      fixed: "left",
      sorter: (a, b) => a.fund.localeCompare(b.fund),
      sortDirections: ["descend", "ascend"],
      render: (value, row) => (
        <Flex
          gap={spaces.space1}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setFundToEdit(row.idFund)
            setOpenModal(true)
          }}
        >
          <b>
            <Ellipsis style={{ maxWidth: 215 }}>{value}</Ellipsis>
          </b>
          {isManagementTeam && (
            <Tooltip title="Edit Assumptions">
              <EditOutlined />
            </Tooltip>
          )}
        </Flex>
      ),
      onCell: mergeCapitalCallAndDistributionLinesFromSameFund,
    },
    {
      title: "Vintage",
      dataIndex: "vintage",
      width: 100,
      align: "center",
      onCell: mergeCapitalCallAndDistributionLinesFromSameFund,
    },
    {
      title: "Expected End of Investment Period",
      dataIndex: "end_of_investment_period",
      width: 100,
      align: "center",
      render: formatDate,
      onCell: mergeCapitalCallAndDistributionLinesFromSameFund,
    },
    {
      title: "Expected Fund Conclusion",
      dataIndex: "fund_conclusion",
      width: 100,
      align: "center",
      render: formatDate,
      onCell: mergeCapitalCallAndDistributionLinesFromSameFund,
    },
    {
      title: "MOIC",
      dataIndex: "moic",
      width: 100,
      align: "center",
      sorter: (a, b) => (a.moic || 0) - (b.moic || 0),
      sortDirections: ["descend", "ascend"],
      render: (value) => <SimpleRoundedValueLabel value={value} />,
      onCell: mergeCapitalCallAndDistributionLinesFromSameFund,
    },
    {
      width: 145,
      title: "Total Performed and Estimated",
      render: (_, row) => {
        let legend = null
        let color = null

        if (row.type == "capitalCall") {
          legend = "CC"
          color = "red"
        } else {
          legend = "Dist."
          color = "green"
        }

        return (
          <Flex style={{ alignContent: "center" }}>
            <Tag color={color} style={{ width: 40, textAlign: "center" }}>
              {legend}
            </Tag>
            <Progress
              steps={10}
              format={() => <p style={{ color: "black" }}>{((row.total || 0) * 100).toFixed(0)}%</p>}
              percent={Math.round(row.total * 100)}
              size="small"
              strokeColor={color}
              style={{ alignContent: "center" }}
            />
          </Flex>
        )
      },
    },
  ]

  quarters.forEach((data) => {
    columns.push({
      title: data.year,
      onHeaderCell: () => ({
        style: {
          border: "1px solid #e8e8e8",
        },
      }),
      children: data.quarters.map((quarter) => ({
        title: quarter,
        dataIndex: `${data.year}-${quarter}`,
        width: 65,
        align: "center",
        render: (quarterData) => <AssumptionLabel value={quarterData?.value} source={quarterData?.source} />,
        onHeaderCell: () => ({
          style: {
            border: "1px solid #e8e8e8",
          },
        }),
      })),
    })
  })

  if (isLoading || isLoadingProjections) return <LoaderFullScreen showRandomFacts />

  return (
    <>
      {openModal && (
        <EditAssumptionsModal
          onClose={() => {
            refresh()
            setOpenModal(false)
            setFundToEdit(null)
            refreshLastAssumptionInserted()
          }}
          idFund={fundToEdit}
        />
      )}
      <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2, width: "100vw" }}>
        <Flex style={{ alignItems: "center", gap: spaces.space2 }}>
          {isManagementTeam && (
            <Button type="primary" onClick={() => setOpenModal(true)}>
              Edit Assumptions
            </Button>
          )}
          <b>Legend:</b>
          <AssumptionsLegend />
          {isNotUpdatedAnalysis && <NewAssumptionsDetected update={update} alert="Update the projections to recalculate MOIC" />}
        </Flex>
        <Flex vertical style={{ gap: spaces.space2 }}>
          <Table columns={columns} dataSource={data} size="small" pagination={false} scroll={{ y: height > 600 ? height * 0.67 : height * 0.5 }} />
        </Flex>
      </Flex>
    </>
  )
}
