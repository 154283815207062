import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DeleteOutlined,
  ExclamationCircleFilled,
  GlobalOutlined,
  HomeOutlined,
  PlusOutlined,
  SlidersOutlined,
  XFilled,
} from "@ant-design/icons"
import { Alert, Button, Card, DatePicker, Flex, Modal, Progress, Space, Statistic, Table, Tooltip } from "antd"
import dayjs from "dayjs"
import { usePortfolioModel } from "../../../hooks/usePortfolioModel"
import { borders, colors, spaces } from "../../../utils/styles"
import { LoaderFullScreen } from "../../atoms/animations/Loader"
import { PercentLabel } from "../../atoms/formatters/PercentLabel"
import { TacticalIndicator } from "../../atoms/TacticalIndicator"
import { IPSPlot } from "../../molecules/charts/IPSPlot"
import { PortfolioModelModal } from "../../molecules/modals/PortfolioModelModal"
import { useAuth } from "../../../hooks/context/useAuth"
import { ClassAndBenchmark } from "../../molecules/ClassAndBenchmark"

export const PortfolioModel = () => {
  const {
    availableDates,
    date,
    isLoading,
    portfolioModel,
    history,
    showModal,
    openModal,
    closeModal,
    newPortfolioModel,
    deletePortfolioModel,
    setDate,
    setSvgPlotOffshore,
    setSvgPlotOnshore,
    exportPlots,
  } = usePortfolioModel()

  const { isManagementTeam } = useAuth()

  const formattedDate = dayjs(portfolioModel?.date).format("MM/YYYY")

  const secureDelete = () => {
    Modal.confirm({
      title: "Excluir Carteira Modelo",
      content: `Você tem certeza que deseja excluir a carteira modelo de ${formattedDate}?`,
      okText: "Excluir",
      okButtonProps: { style: { backgroundColor: colors.primary } },
      cancelText: "Cancelar",
      onOk: deletePortfolioModel,
    })
  }

  const columns = [
    {
      title: "Classe de Ativo",
      dataIndex: "assetClass",
      key: "assetClass",
      width: 170,
      render: (_, row) => <ClassAndBenchmark className={row.assetClass} benchmarkName={row.benchmark} bloombergTicker={row.ticker} />,
    },
    {
      title: "Peso",
      dataIndex: "weight",
      key: "weight",
      width: 50,
      render: (value) => <PercentLabel value={value} decimalPlaces={0} />,
    },
    {
      title: "Posição tática",
      dataIndex: "tacticalPosition",
      key: "tacticalPosition",
      width: 110,
      render: (value) => {
        let color = colors.blue
        let text = "Neutral"

        if (value.includes("BULLISH")) {
          color = "green"
          text = "Overweight"
        }

        if (value.includes("BEARISH")) {
          color = "red"
          text = "Underweight"
        }

        return (
          <Flex style={{ gap: spaces.space1, color }}>
            <XFilled style={{ color }} />
            {text}
          </Flex>
        )
      },
    },
  ]

  const historyColumns = [
    {
      key: 0,
      title: "",
      dataIndex: "locale",
      width: 50,
      render: (value) =>
        value === "onshore" ? (
          <Flex gap={spaces.space2}>
            <HomeOutlined style={{ fontSize: "20px" }} />
            Onshore
          </Flex>
        ) : (
          <Flex gap={spaces.space2}>
            <GlobalOutlined style={{ fontSize: "20px" }} />
            Offshore
          </Flex>
        ),
    },
    {
      key: 1,
      title: "Classe do Ativo",
      dataIndex: "assetClass",
      width: 160,
      render: (_, row) => <ClassAndBenchmark className={row.assetClass} benchmarkName={row.benchmark} bloombergTicker={row.ticker} />,
    },
    {
      key: 2,
      title: "Peso",
      dataIndex: "weight",
      width: 75,
      render: (value) => <PercentLabel value={value} decimalPlaces={0} />,
    },
  ]

  if (history.length > 0) {
    const dateFormatRegex = /^\d{4}-\d{2}-\d{2}$/
    const isDate = (key) => dateFormatRegex.test(key)

    Object.keys(history[0] || {}).forEach((key) => {
      if (!isDate(key)) return

      historyColumns.push({
        key: key,
        title: dayjs(key).format("MM/YYYY"),
        dataIndex: key,
        width: 100,
        align: "center",
        render: (value) => {
          const legend = {
            VERY_BEARISH: "Strong Underweight",
            BEARISH: "Underweight",
            NEUTRAL: "Neutral",
            BULLISH: "Overweight",
            VERY_BULLISH: "Strong Overweight",
          }

          return (
            <Tooltip title={legend[value]}>
              <Flex style={{ justifyContent: "center" }}>{value ? <TacticalIndicator signal={value} /> : "Sem posição"}</Flex>
            </Tooltip>
          )
        },
      })
    })
  }

  const CardLocale = ({ icon, weight, title, dataSource, statistics, setSVG }) => (
    <Card
      title={
        <Flex style={{ gap: spaces.space2, paddingLeft: spaces.space1 }}>
          {icon}
          <h3 style={{ margin: 0 }}>{title}</h3>
        </Flex>
      }
      extra={
        <Progress
          percent={weight * 100}
          size="small"
          trailColor={colors.gray}
          strokeColor={colors.primary}
          type="circle"
          style={{ transform: "scale(0.8)" }}
        />
      }
      size="small"
    >
      <Flex vertical style={{ gap: spaces.space3, alignItems: "center" }}>
        <Flex gap={spaces.space0}>
          <Table
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            size="small"
            rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
            style={{ border: borders.default, borderRadius: 6, height: "auto" }}
          />
          <IPSPlot
            key={title}
            data={dataSource?.map(({ assetClass, weight, strategy }) => ({
              class_name: assetClass,
              target: weight,
              strategy,
            }))}
            width={600}
            height={300}
            setSVG={setSVG}
          />
        </Flex>
        {statistics ? (
          <Space style={{ gap: spaces.space2 }} size="large" wrap>
            <Statistic
              title="Expected Return"
              value={(statistics.expectedReturn * 100).toFixed(2)}
              prefix={<ArrowUpOutlined />}
              suffix="%"
              valueStyle={{ color: "green" }}
            />
            <Statistic
              title="Expected Return (Liquid)"
              value={(statistics.expectedReturnLiquid * 100).toFixed(2)}
              prefix={<ArrowUpOutlined />}
              suffix="%"
              valueStyle={{ color: "green" }}
            />
            <Statistic
              title="Expected Real Return (Liquid)"
              value={(statistics.expectedReturnRealLiquid * 100).toFixed(2)}
              prefix={<ArrowUpOutlined />}
              suffix="%"
              valueStyle={{ color: "green" }}
            />
            <Statistic title="CPI" value={(statistics.cpi * 100).toFixed(2)} suffix="%" />
            <Statistic title="Vol." value={(statistics.volatility * 100).toFixed(2)} suffix="%" />
            <Statistic
              title={
                <Flex>
                  Sharpe Ratio
                  <Tooltip title="Expected Return (Liquid) / Volatility" placement="right">
                    <ExclamationCircleFilled style={{ marginLeft: spaces.space1, color: colors.primary }} />
                  </Tooltip>
                </Flex>
              }
              value={statistics.sharpeRatio.toFixed(2)}
            />
            <Statistic
              title="Max DD"
              value={(statistics.maxDD * 100).toFixed(2)}
              prefix={<ArrowDownOutlined />}
              suffix="%"
              valueStyle={{ color: "red" }}
            />
          </Space>
        ) : (
          <Alert
            message={`Você precisa inserir os retornos experados dos indicadores do mês selecionado para visualizar as estatísticas.`}
            type="warning"
            showIcon
          />
        )}
      </Flex>
    </Card>
  )

  if (isLoading || !portfolioModel) return <LoaderFullScreen />

  return (
    <>
      {showModal && <PortfolioModelModal onClose={closeModal} portfolioModel={portfolioModel} onSubmit={newPortfolioModel} />}
      <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2, backgroundColor: colors.gray, width: "100%", justifyContent: "center" }}>
        <Flex gap={spaces.space1}>
          <DatePicker.MonthPicker
            placeholder="Selecionar mês de referência"
            style={{ width: 230 }}
            value={dayjs(date)}
            onChange={(date) => setDate(dayjs(date).format("YYYY-MM-DD"))}
            disabledDate={(date) => !availableDates.includes(dayjs(date).format("YYYY-MM-DD"))}
            allowClear={false}
            format="MM/YYYY"
          />
          <Button type="primary" onClick={secureDelete} disabled={!isManagementTeam}>
            <DeleteOutlined />
            Excluir
          </Button>
          <Button type="primary" onClick={openModal} style={{ backgroundColor: colors.green }} disabled={!isManagementTeam}>
            <PlusOutlined />
            Nova
          </Button>
          {/* <Button type="primary" onClick={exportPlots} style={{ backgroundColor: "black" }} icon={<CloudDownloadOutlined />}>
            Exportar gráficos
          </Button> */}
        </Flex>
        <Flex gap={spaces.space2}>
          <CardLocale
            dataSource={portfolioModel?.offshore?.portfolio}
            statistics={portfolioModel?.offshore?.statistics}
            weight={portfolioModel?.offshore?.weight}
            title="Offshore"
            icon={<GlobalOutlined style={{ fontSize: "20px" }} />}
            // setSVG={setSvgPlotOffshore}
          />
          <CardLocale
            dataSource={portfolioModel?.onshore?.portfolio}
            statistics={portfolioModel?.onshore?.statistics}
            weight={portfolioModel?.onshore?.weight}
            title="Onshore"
            icon={<HomeOutlined style={{ fontSize: "20px" }} />}
            // setSVG={setSvgPlotOnshore}
          />
        </Flex>
        <Card
          title={
            <Flex gap={spaces.space2}>
              <SlidersOutlined style={{ fontSize: "20px" }} />
              <h3>Histórico de posições táticas</h3>
            </Flex>
          }
          styles={{ body: { padding: spaces.space0 } }}
        >
          <Table columns={historyColumns} dataSource={history} size="small" pagination={false} />
        </Card>
      </Flex>
    </>
  )
}
