import { Checkbox, Flex, Radio, Table, Tag } from "antd"
import { useMaturityContext } from "../../../context/MaturityContext"
import { useAuth } from "../../../hooks/context/useAuth"
import { useMaturity } from "../../../hooks/useMaturity"
import { useViewport } from "../../../hooks/utils/useViewport"
import { formatDate } from "../../../utils/date"
import { CURRENCY } from "../../../utils/enums"
import { spaces } from "../../../utils/styles"
import { Loader } from "../../atoms/animations/Loader"
import { Ellipsis } from "../../atoms/formatters/Ellipsis"
import { MoneyLabel } from "../../atoms/formatters/MoneyLabel"
import { SimpleRoundedValueLabel } from "../../atoms/formatters/SimpleRoundedValueLabel"
import { AssetNameFilter } from "../../atoms/input/AssetNameFilter"
import { EditableLabel } from "../../atoms/input/EditableLabel"
import { CustomerOrFundSelect, MODES } from "../../atoms/select/CustomerOrFundSelect"

export const Maturity = () => {
  const { assets, isLoading, updateDecision } = useMaturity()
  const { setMode, setCustomerOrFund, setAssetName, mode, showOnlyNextMaturities, setShowOnlyNextMaturities } = useMaturityContext()
  const { height: heightScreen } = useViewport()
  const { isManagementTeam } = useAuth()

  const columns = [
    {
      title: mode === MODES.CUSTOMER ? "Cliente" : "Fundo",
      dataIndex: "customerOrFund",
      key: "customerOrFund",
      render: (_, row) => <Tag color={row.color}>{row.customerOrFund}</Tag>,
    },
    {
      title: "Ativo",
      dataIndex: "asset",
      key: "asset",
      width: 300,
      render: (value) => (
        <Ellipsis style={{ width: 300 }} data-hj-supress>
          {value}
        </Ellipsis>
      ),
    },
    {
      title: "Vencimento",
      dataIndex: "maturity",
      key: "maturity",
      render: (value) => formatDate(value),
    },
    {
      title: "Cotas",
      dataIndex: "quantity",
      key: "quantity",
      render: (value) => <SimpleRoundedValueLabel value={value} />,
    },
    {
      title: "Valor (R$)",
      dataIndex: "value_brl",
      key: "value_brl",
      render: (value) => <MoneyLabel value={value} currency={CURRENCY.brl} />,
    },
    {
      title: "Valor (US$)",
      dataIndex: "value_usd",
      key: "value_usd",
      render: (value) => <MoneyLabel value={value} currency={CURRENCY.usd} />,
    },
    {
      title: "Acompanhamento",
      dataIndex: "decision",
      key: "decision",
      minWidth: 200,
      render: (_, row) => {
        const update = (value) => {
          if (!Boolean(value) && !Boolean(row.decision)) return
          updateDecision(row.dimAssetId, row.dimCustomerId, value)
        }

        return (
          <EditableLabel
            value={row.decision}
            placeholder={"Clique aqui para registrar decisão"}
            onSubmit={update}
            width={200}
            key={`${row.dimAssetId}_${row.dimCustomerId}`}
            readOnly={!isManagementTeam}
            data-hj-supress
          />
        )
      },
    },
  ]

  return (
    <Flex style={{ padding: spaces.space2, height: "100%", justifyContent: "center", alignItems: "flex-start" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <Flex vertical style={{ gap: spaces.space2, alignItems: "baseline" }}>
          <Flex style={{ alignItems: "center", gap: spaces.space2 }}>
            <AssetNameFilter setValue={setAssetName} />
            <CustomerOrFundSelect mode={mode} setValue={setCustomerOrFund} />
            <Radio.Group
              value={mode}
              onChange={(e) => {
                setMode(e.target.value)
                setCustomerOrFund(null)
              }}
            >
              <Radio value={MODES.CUSTOMER}>Cliente</Radio>
              <Radio value={MODES.FUND}>Fundos TERA</Radio>
            </Radio.Group>
            <Checkbox checked={showOnlyNextMaturities} onChange={(e) => setShowOnlyNextMaturities(e.target.checked)} defaultChecked={true}>
              Mostrar apenas vencimentos dos próximos 30 dias
            </Checkbox>
          </Flex>
          <Table
            columns={columns}
            dataSource={assets}
            rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
            style={{ marginBottom: spaces.space2, width: "97vw" }}
            pagination={{ pageSize: heightScreen > 551 ? 13 : 6, showSizeChanger: false }}
            scroll={{ y: "79vh" }}
            locale={{ emptyText: "Nenhum vencimento previsto" }}
            size="small"
          />
        </Flex>
      )}
    </Flex>
  )
}
