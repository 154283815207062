import { useEffect, useMemo, useState } from "react"
import { saveSvgAsPng } from "save-svg-as-png"
import { PortfolioModelService } from "../service/PortfolioModelService"
import { useSupabase } from "./context/useSupabase"
import { useToast } from "./context/useToast"
import { useLoading } from "./utils/useLoading"

export const usePortfolioModel = () => {
  const [date, setDate] = useState(null)
  const [availableDates, setAvailableDates] = useState([])
  const [portfolioModel, setPortfolioModel] = useState(null)
  const [history, setHistory] = useState([])
  const [showModal, setShowModal] = useState(false)
  const [svgPlotOffshore, setSvgPlotOffshore] = useState(null)
  const [svgPlotOnshore, setSvgPlotOnshore] = useState(null)

  const supabase = useSupabase()
  const { isLoading, setIsLoading, reload, refresh } = useLoading()
  const service = useMemo(() => PortfolioModelService(supabase), [supabase])
  const toast = useToast()

  const fetch = async () => {
    setIsLoading(true)
    setPortfolioModel(await service.getPortfolioModelByDate(date))
    setIsLoading(false)
  }

  useEffect(() => {
    service.getAvailableDates().then(setAvailableDates)
    service.getHistoryTacticalPosition().then(setHistory)
  }, [reload])

  useEffect(() => {
    setDate(availableDates[0])
  }, [availableDates])

  useEffect(() => {
    date && fetch()
  }, [reload, date])

  const openModal = () => setShowModal(true)

  const closeModal = () => setShowModal(false)

  const newPortfolioModel = async (values) => {
    setIsLoading(true)
    await service.newPortfolioModel(values)
    setIsLoading(false)
    toast.success({ message: "Carteira modelo criada" })
    refresh()
    closeModal()
  }

  const deletePortfolioModel = async () => {
    setIsLoading(true)
    await service.deletePortfolioModel(portfolioModel.id)
    setIsLoading(false)
    toast.success({ message: "Carteira modelo deletada" })
    refresh()
  }

  const exportPlots = () => {
    saveSvgAsPng(svgPlotOnshore, "portfolio_model_offshore.png", { scale: 2 })
    saveSvgAsPng(svgPlotOffshore, "portfolio_model_onshore.png", { scale: 2 })
  }

  return {
    availableDates,
    isLoading,
    portfolioModel,
    history,
    showModal,
    date,
    openModal,
    closeModal,
    newPortfolioModel,
    deletePortfolioModel,
    setDate,
    setSvgPlotOffshore,
    setSvgPlotOnshore,
    exportPlots,
  }
}
