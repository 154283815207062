import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { MODES } from "../components/atoms/select/CustomerOrFundSelect"
import { useMaturityContext } from "../context/MaturityContext"
import { getLastDateAvailable } from "../service/PositionsService"
import { tables } from "../utils/utils"
import { useSupabase } from "./context/useSupabase"
import { useToast } from "./context/useToast"
import { useLoading } from "./utils/useLoading"

export const useMaturity = () => {
  const [assets, setAssets] = useState([])
  const { mode, customerOrFund, assetName, showOnlyNextMaturities } = useMaturityContext()
  const supabase = useSupabase()
  const { isLoading, setIsLoading } = useLoading()
  const toast = useToast()

  useEffect(() => {
    const fetch = async () => {
      setIsLoading(true)

      const table = tables.FACT_ASSET_ALLOCATION
      const customer = tables.DIM_CUSTOMER
      const fund = tables.DIM_TERA_FUND
      const asset = tables.DIM_ASSET

      const date = await getLastDateAvailable(supabase, table)

      const baseTable = mode === MODES.CUSTOMER ? customer : fund

      const query = supabase
        .from(table)
        .select(
          `
            quantity,
            value_brl,
            value_usd,
            ${baseTable} (id, name, color),
            ${asset}!inner (id, name, maturity)
            ${customer}!inner (id),
          `,
        )
        .eq("date", date)
        .filter(`${asset}.maturity`, "gte", date)
        .not(`${asset}.maturity`, "is", null)

      if (mode === MODES.CUSTOMER) query.filter(`${fund}_id`, "is", null)
      if (mode === MODES.FUND) query.filter(`${customer}_id`, "is", null)
      if (mode === MODES.CUSTOMER && customerOrFund) query.eq(`${customer}_id`, customerOrFund)
      if (mode === MODES.FUND && customerOrFund) query.eq(`${fund}_id`, customerOrFund)
      if (assetName && assetName.length > 1) query.ilike(`${asset}.name`, `%${assetName}%`)
      if (showOnlyNextMaturities) query.filter(`${asset}.maturity`, "lte", dayjs().add(1, "month").format("YYYY-MM-DD"))

      const { data } = await query

      const { data: decisions } = await supabase.from(tables.MATURITY_DECISION).select("*").filter("deleted_at", "is", null)

      setAssets(
        data
          .map(
            ({
              quantity,
              value_brl,
              value_usd,
              [baseTable]: { id: dimCustomerId, name: customerOrFund, color },
              [asset]: { id: dimAssetId, name: assetName, maturity },
            }) => ({
              value_brl,
              value_usd,
              customerOrFund,
              asset: assetName,
              maturity,
              quantity,
              color,
              decision: decisions.find((decision) => decision.dim_asset_id === dimAssetId && decision.dim_customer_id === dimCustomerId)?.decision,
              dimAssetId,
              dimCustomerId,
            }),
          )
          .sort((a, b) => new Date(a.maturity) - new Date(b.maturity)),
      )
      setIsLoading(false)
    }

    fetch()
  }, [customerOrFund, mode, assetName, showOnlyNextMaturities])

  const updateDecision = async (asset, customer, decision) => {
    await supabase.from(tables.MATURITY_DECISION).update({ deleted_at: new Date() }).eq("dim_asset_id", asset).eq("dim_customer_id", customer)
    if (decision) await supabase.from(tables.MATURITY_DECISION).insert([{ dim_asset_id: asset, dim_customer_id: customer, decision }])
    toast.success({ message: "Decisão atualizada." })
  }

  return { assets, isLoading, updateDecision }
}
