import { useState } from "react"

export const useLoading = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [reload, setReload] = useState(0)
  const refresh = () => setReload((prev) => prev + 1)

  const runWithLoader = (fn) => {
    setIsLoading(true)

    fn()
      .then(() => setIsLoading(false))
      .catch(() => setIsLoading(false))

    return () => {}
  }

  return { isLoading, setIsLoading, reload, refresh, runWithLoader }
}
