import { Form } from "antd"
import { useEffect, useState } from "react"
import { useService } from "../../context/useService"
import { useIPS } from "../../useAssetAllocation"
import { ASSET_LOCALES } from "../../../utils/enums"
import { useLoading } from "../../utils/useLoading"

export const useLiquidity = () => {
  const [customers, setCustomers] = useState([])
  const [customerId, setCustomerId] = useState(null)
  const [expectedDividendsPerShare, setExpectedDividendsPerShare] = useState([])
  const [shares, setShares] = useState([])
  const [cashflow, setCashflow] = useState([])
  const [estimatedCashflow, setEstimatedCashflow] = useState([])
  const [liquidity, setLiquidity] = useState(0)

  const [form] = Form.useForm()
  const { dimCustomerService, expectedDividendPerShareService, shareService, factPrivateInvestmentsCashflowEstimated } = useService()
  const { isLoading, setIsLoading, runWithLoader } = useLoading()
  const { ips, isLoading: isLoadingIPS } = useIPS({
    locale: ASSET_LOCALES.all.value,
    selectedCustomer: customers.find((customer) => customer.id === customerId)?.name,
  })

  const refreshBalance = async () => {
    if (!customerId) return

    let balanceUsd = liquidity || 0

    const result = estimatedCashflow.map((item) => {
      const semester = item.semester

      const inflow = form.getFieldValue(["inflows", semester]) || 0
      const outflow = form.getFieldValue(["outflows", semester]) || 0

      balanceUsd += item.netCashflowUsd + inflow - outflow

      if (!shares.length) return { ...item, balanceUsd }

      const year = semester.split("-")[0]

      const dividends = Object.entries(form.getFieldValue("dividendsPerShare")).reduce((acc, [asset, years]) => {
        if (!form.getFieldValue(asset)) return acc

        const dividendPerShare = (years[year] || 0) / 2
        const quantity = shares.find((share) => share.asset === asset)?.shares || 0

        if (!acc[asset]) acc[asset] = 0

        const totalValue = dividendPerShare * quantity
        acc[asset] += totalValue
        balanceUsd += totalValue

        return acc
      }, {})

      return { ...item, dividends, balanceUsd }
    })

    setCashflow(result)
  }

  useEffect(
    () =>
      runWithLoader(async () => {
        setCustomers(await dimCustomerService.getAll())

        const result = (await expectedDividendPerShareService.getAll()).reduce((acc, item) => {
          const asset = item.asset
          const year = item.year
          const dividend = item.dividend

          const assetIndex = acc.findIndex((item) => item.asset === asset)

          if (assetIndex === -1) acc.push({ asset, [year]: dividend, assetId: item.assetId })
          else acc[assetIndex][year] = dividend

          return acc
        }, [])

        setExpectedDividendsPerShare(result)
      }),
    [],
  )

  useEffect(
    () =>
      runWithLoader(async () => {
        if (!customerId) return

        setShares([])

        await Promise.all(
          expectedDividendsPerShare.map(async (item) => {
            let quantity = 0

            if (item.asset === "PAX US") quantity = await shareService.getPAXSharesByCustomer(customerId)
            else quantity = await shareService.getGenericSharesByCustomer(customerId, item.assetId)

            if (!quantity) return

            form.setFieldValue(item.asset, true)
            setShares((prev) => [...prev, { asset: item.asset, shares: quantity }])
          }),
        )

        const initialCashflow = await factPrivateInvestmentsCashflowEstimated.getByCustomer(customerId)
        setEstimatedCashflow(initialCashflow)
      }),
    [customerId],
  )

  useEffect(() => {
    if (ips?.length) resetValues()
  }, [ips])

  useEffect(() => setIsLoading(isLoadingIPS), [isLoadingIPS])

  useEffect(() => {
    runWithLoader(() => refreshBalance())
  }, [liquidity, estimatedCashflow])

  useEffect(() => {
    if (!shares.length || !shares.some((share) => share.shares)) return

    form.setFieldsValue({
      dividendsPerShare: expectedDividendsPerShare.reduce((acc, item) => {
        if (!shares.find((share) => share.asset === item.asset)?.shares) return acc

        if (!acc[item.asset]) acc[item.asset] = {}

        Object.keys(item).forEach((key) => {
          if (key !== "asset" && key !== "assetId") acc[item.asset][key] = item[key]
        })

        return acc
      }, {}),
    })
  }, [expectedDividendsPerShare, shares])

  const onValuesChange = (_, changedValues) => {
    if (changedValues.customer) setCustomerId(changedValues.customer)
    if (changedValues.liquiditySources) {
      setLiquidity(ips?.reduce((acc, row) => (form.getFieldValue(["liquiditySources", row.class_name]) === true ? acc + row.amount : acc), 0))
    }
    refreshBalance()
  }

  const classes = ips?.map((row) => ({ assetClass: row.class_name, amount: row.amount }))

  const availableYears = cashflow.reduce((acc, row) => {
    const year = row.semester.split("-")[0]
    if (!acc.includes(year)) acc.push(year)
    return acc
  }, [])

  const resetValues = () => {
    form.setFieldsValue({ liquiditySources: { Cash: true } })
    setLiquidity(ips?.find((row) => row.class_name === "Cash")?.amount)
  }

  return {
    form,
    customers,
    classes,
    liquidity,
    isCustomerSelected: !!customerId,
    availableYears,
    cashflow,
    isLoading,
    shares,
    expectedDividendsPerShare,
    onValuesChange,
    resetValues,
  }
}
