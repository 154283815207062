import { tables } from "../../utils/utils"

export const FactPrivateInvestmentsAnalysisService = (supabase) => {
  const getAll = async ({ dimCustomerId, dimAssetId }) => {
    const query = supabase.from(tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS).select(`
        contribution_usd,
        contribution_rate,
        cumulative_contribution_rate,
        quarter,
        semester,
        dim_asset(id, name),
        distribution_usd,
        distribution_rate,
        cumulative_distribution_rate,
        growth_rate,
        type,
        commitment_usd,
        dpi,
        unfunded_capital_usd,
        irr,
        net_cash_flow_usd,
        nav_usd,
        moic,
        created_at
    `)

    if (dimCustomerId) query.filter("dim_customer_id", "eq", dimCustomerId)
    if (dimAssetId) query.filter("dim_asset_id", "eq", dimAssetId)

    const { data } = await query.order("quarter", { ascending: true })

    return data.map((item) => ({
      contributionUsd: item.contribution_usd,
      contributionRate: item.contribution_rate,
      cumulativeContributionRate: item.cumulative_contribution_rate,
      quarter: item.quarter,
      semester: item.semester,
      dimAsset: item.dim_asset,
      distributionRate: item.distribution_rate,
      distributionUsd: item.distribution_usd,
      cumulativeDistributionRate: item.cumulative_distribution_rate,
      growthRate: item.growth_rate,
      type: item.type,
      commitmentUsd: item.commitment_usd,
      dpi: item.dpi,
      unfundedCapitalUsd: item.unfunded_capital_usd,
      irr: item.irr,
      netCashFlowUsd: item.net_cash_flow_usd,
      navUsd: item.nav_usd,
      moic: item.moic,
      createdAt: item.created_at,
    }))
  }

  const getLastUpdate = async () => {
    const { data } = await supabase
      .from(tables.FACT_PRIVATE_INVESTMENTS_ANALYSIS)
      .select("created_at")
      .order("created_at", { ascending: false })
      .limit(1)

    return data?.[0]?.created_at || null
  }

  return { getAll, getLastUpdate }
}
