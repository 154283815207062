import * as d3 from "d3"
import { useEffect, useRef } from "react"

export const GrowthRatePlot = ({ data, width = 740, height = 310 }) => {
  const ref = useRef()

  useEffect(() => {
    d3.select(ref.current).selectAll("*").remove()

    const svg = d3.select(ref.current).attr("width", width).attr("height", height).append("g").attr("transform", "translate(50, -40)")

    svg
      .append("text")
      .attr("x", 350)
      .attr("y", 60)
      .attr("text-anchor", "middle")
      .attr("font-size", "18px")
      .attr("font-weight", "bold")
      .text("CAGR - Growth Rate")

    const x = d3
      .scaleBand()
      .domain(data.map((d) => d.semester))
      .range([0, 685])
      .paddingInner(0.1)
      .paddingOuter(-0.45)

    const maxGrowthRate = d3.max(data, (d) => d.growthRate) || 1
    const y = d3
      .scaleLinear()
      .domain([0, maxGrowthRate + 0.2])
      .range([360, 140])

    const chartGroup = svg.append("g").attr("transform", "translate(0, -60)")

    chartGroup
      .append("g")
      .attr("transform", "translate(0,360)")
      .call(d3.axisBottom(x))
      .selectAll("text")
      .attr("transform", "rotate(-70)")
      .attr("text-anchor", "middle")
      .attr("x", -27)
      .attr("dy", 0)

    chartGroup.append("g").call(d3.axisLeft(y).ticks(10).tickFormat(d3.format(".2f")))

    chartGroup
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", -250)
      .attr("y", -35)
      .attr("text-anchor", "middle")
      .attr("font-size", "12px")
      .text("CAGR (Compound Annual Growth Rate)")

    chartGroup
      .append("g")
      .attr("class", "grid")
      .attr("transform", "translate(0,360)")
      .call(
        d3
          .axisBottom(x)
          .tickSize(-(360 - 140))
          .tickFormat(""),
      )
      .selectAll("line")
      .style("stroke", "#ccc")

    chartGroup.append("g").attr("class", "grid").call(d3.axisLeft(y).tickSize(-705).tickFormat("")).selectAll("line").style("stroke", "#ccc")

    const line = d3
      .line()
      .x((d) => x(d.semester) + x.bandwidth() / 2)
      .y((d) => y(d.growthRate))
      .defined((d) => d.growthRate !== null)

    const getMaxPerSemester = (data) => {
      const grouped = {}
      data.forEach((d) => {
        if (!grouped[d.semester] || d.quarter > grouped[d.semester].quarter) {
          grouped[d.semester] = d
        }
      })
      return Object.values(grouped)
    }

    const performed = getMaxPerSemester(data.filter((d) => d.type === "performed"))
    const estimated = getMaxPerSemester(data.filter((d) => d.type === "estimated"))

    if (estimated.length && performed.length) {
      const intersection = {
        semester: performed[performed.length - 1].semester,
        growthRate: performed[performed.length - 1].growthRate,
      }

      estimated.unshift(intersection)
    }

    chartGroup
      .append("path")
      .datum(performed)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 3)
      .attr("d", line)
      .attr("stroke-dasharray", (d) => (d.type === "estimated" ? "4 4" : "0"))

    chartGroup
      .append("path")
      .datum(estimated)
      .attr("fill", "none")
      .attr("stroke", "blue")
      .attr("stroke-width", 3)
      .attr("stroke-dasharray", "4 4")
      .attr("d", line)
  }, [data])

  return <svg ref={ref} style={{ minWidth: width, minHeight: height }} />
}
