export const distinctObjects = (array) => {
  const seen = new Set()

  return array.filter((item) => {
    const serialized = JSON.stringify(item)
    if (seen.has(serialized)) return false
    seen.add(serialized)
    return true
  })
}
