import { useContext } from "react"
import { GlobalContext } from "../context/GlobalContext"
import { tables } from "../utils/utils"

export const useAssetClass = () => {
  const { supabase } = useContext(GlobalContext)
  return {
    getAssetClass: async ({ locale = null } = {}) => {
      const query = supabase.from(tables.DIM_ASSET_CLASS).select("id, name, id_parent").order("name")
      if (locale) query.filter("locale", "eq", locale)
      return (await query).data
    },
    updateAssetClass: async (idAsset, idClass) => {
      await supabase.from(tables.DIM_ASSET).update({ dim_asset_class_id: idClass }).eq("id", idAsset)
    },
    getParentAssetClass: async (locale) => {
      const query = supabase
        .from(tables.DIM_ASSET_CLASS)
        .select("id, name")
        .order("name")
        .filter("locale", "eq", locale)
        .filter("id_parent", "is", "null")

      return (await query).data
    },
  }
}
