import { useEffect, useState } from "react"
import { useService } from "../../context/useService"
import { useLoading } from "../../utils/useLoading"

export const TERA_SOURCE = "TERA"
export const MANAGER_SOURCE = "Gestor"
export const CAPITAL_CALL = "capital_call"
export const DISTRIBUTION = "distribution"

export const useAssumptions = () => {
  const [data, setData] = useState([])
  const [estimatives, setEstimatives] = useState([])
  const [openModal, setOpenModal] = useState(false)
  const [fundToEdit, setFundToEdit] = useState(null)
  const { privateInvestmentsService } = useService()
  const { refresh, reload, isLoading, setIsLoading } = useLoading()

  const mapQuarters = () => {
    const yearMap = {}

    data.forEach((obj) => {
      Object.keys(obj).forEach((key) => {
        if (/\b\d{4}-Q[1-4]\b/.test(key)) {
          const [year, quarter] = key.split("-")
          if (!yearMap[year]) yearMap[year] = new Set()
          yearMap[year].add(quarter)
        }
      })
    })

    return Object.entries(yearMap).map(([year, quartersSet]) => ({
      year,
      quarters: Array.from(quartersSet),
    }))
  }

  const quarters = mapQuarters()

  const fetch = async () => {
    setIsLoading(true)
    const data = await privateInvestmentsService.getAssumptionsAndInfo()
    setData(data)
    setIsLoading(false)
  }

  useEffect(() => {
    fetch()
  }, [reload])

  return {
    data,
    quarters,
    estimatives,
    setEstimatives,
    openModal,
    setOpenModal,
    fundToEdit,
    setFundToEdit,
    refresh,
    isLoading,
  }
}
