import { convertDateToSemester } from "../../utils/date"
import { tables } from "../../utils/utils"

export const FactPrivateInvestmentsCashflowEstimated = (supabase) => {
  const getByCustomer = async (id) => {
    const CURRENT_SEMESTER = convertDateToSemester(new Date())

    const { data } = await supabase
      .from(tables.FACT_PRIVATE_INVESTMENTS_ESTIMATED_CASHFLOW)
      .select("*")
      .filter("dim_customer_id", "eq", id)
      .filter("semester", "gte", CURRENT_SEMESTER)

    return data.map((row) => ({
      contributionUsd: row.contribution_usd,
      distributionUsd: row.distribution_usd,
      netCashflowUsd: row.net_cash_flow_usd,
      semester: row.semester,
    }))
  }

  return { getByCustomer }
}
