import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, CloudDownloadOutlined, ExclamationCircleFilled, FileExcelFilled } from "@ant-design/icons"
import { Button, Flex, Space, Table, Tooltip } from "antd"
import { useEffect, useState } from "react"
import { saveSvgAsPng } from "save-svg-as-png"
import { useIPS } from "../../../hooks/useAssetAllocation"
import { borders, colors, spaces } from "../../../utils/styles"
import { downloadExcelFile, round } from "../../../utils/utils"
import { LoaderFullScreen } from "../../atoms/animations/Loader"
import { MoneyLabel } from "../../atoms/formatters/MoneyLabel"
import { PercentLabel } from "../../atoms/formatters/PercentLabel"
import { AssetLocaleSelect } from "../../atoms/select/AssetLocaleSelect"
import { CustomerOrFundSelect, MODES } from "../../atoms/select/CustomerOrFundSelect"
import { IPSPlot } from "../../molecules/charts/IPSPlot"
import { ClassAndBenchmark } from "../../molecules/ClassAndBenchmark"

export const IPS = () => {
  const [svg, setSVG] = useState(null)
  const [locale, setLocale] = useState("offshore")
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const { ips, excelAllClients, totals, customers, isLoading } = useIPS({ locale, selectedCustomer })

  useEffect(() => {
    setSelectedCustomer(null)
  }, [locale])

  useEffect(() => {
    if (!selectedCustomer && customers.length && !customers.includes(selectedCustomer)) setSelectedCustomer(customers[0])
  }, [customers])

  if (isLoading) return <LoaderFullScreen />

  const getBalance = (row) => {
    const diff = round(row.current || 0, 4) - round(row.target || 0, 4)
    const margin = 0.01
    const amount = totals[selectedCustomer] * diff
    const balance = { diff, amount }

    if (Math.abs(diff) < margin) return { color: "green", icon: <CheckOutlined />, legend: "dentro do alvo", isTargetReached: true, ...balance }
    if (diff > 0) return { color: "red", icon: <ArrowDownOutlined />, legend: "para atingir o alvo", ...balance }
    return { color: "#0288D1", icon: <ArrowUpOutlined />, legend: "para atingir o alvo", ...balance }
  }

  let columns = [
    {
      title: "Estratégia",
      dataIndex: "strategy",
      key: "strategy",
    },
    {
      title: "Classe",
      dataIndex: "class_name",
      key: "class_name",
      render: (className, row) => <ClassAndBenchmark className={className} benchmarkName={row.benchmark_name} bloombergTicker={row.bloomberg_ticker} />,
    },
    {
      title: "Posição atual",
      dataIndex: "current",
      key: "current",
      render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
    },
    {
      title: "Alvo",
      dataIndex: "target",
      key: "target",
      render: (value) => <PercentLabel value={value} decimalPlaces={2} />,
    },
    {
      title: "Balanço (%)",
      dataIndex: "balance",
      key: "balance",
      render: (_, row) => {
        const { color, icon, legend, diff, isTargetReached } = getBalance(row)
        return (
          <Space style={{ color }}>
            {icon}
            {!isTargetReached && <PercentLabel value={Math.abs(diff)} decimalPlaces={2} />}
            {legend}
          </Space>
        )
      },
    },
    {
      title: "Balanço (US$)",
      dataIndex: "amount",
      key: "amount",
      render: (_, row) => {
        const { amount, color, isTargetReached } = getBalance(row)
        return <Space style={{ color }}>{isTargetReached ? "OK" : <MoneyLabel value={Math.abs(amount)} currency="USD" />}</Space>
      },
    },
  ]

  const handleExport = () => {
    if (!excelAllClients.length) return
    const columns = Object.keys(excelAllClients[0]).map((column) => ({ name: column, width: 20 }))
    downloadExcelFile(excelAllClients, "IPS", columns, "IPS.xlsx")
  }

  const handleExportPlot = () =>
    saveSvgAsPng(svg, `IPS_${selectedCustomer}_${locale === "all" ? "CONSOLIDADO" : locale.toUpperCase()}.png`, { scale: 2 })

  return (
    <Flex vertical style={{ width: "max-content", minWidth: "100vw" }}>
      <Space style={{ borderBottom: borders.default, padding: spaces.space2, backgroundColor: colors.gray }}>
        <AssetLocaleSelect value={locale} setValue={setLocale} />
        <CustomerOrFundSelect
          key={locale}
          mode={MODES.CUSTOMER}
          value={selectedCustomer}
          setValue={setSelectedCustomer}
          isToUseNameAsValue={true}
          customList={customers.map((customer) => ({ name: customer }))}
          hasDefaultOption={false}
        />
        <Button type="primary" style={{ backgroundColor: colors.green }} icon={<FileExcelFilled />} onClick={handleExport}>
          Exportar IPS de todos os clientes
        </Button>
        <Button type="primary" icon={<CloudDownloadOutlined />} onClick={handleExportPlot}>
          Exportar gráfico
        </Button>
      </Space>
      <Space
        style={{ justifyContent: "center", flexDirection: "row", gap: spaces.space1, paddingTop: spaces.space1, height: 700, width: "max-content" }}
      >
        <Space style={{ marginRight: spaces.space2 }}>
          <IPSPlot data={ips} setSVG={setSVG} />
        </Space>
        <Table
          dataSource={ips}
          columns={columns}
          pagination={false}
          size="small"
          rowClassName={(_, index) => (index % 2 === 0 ? "table-row-dark" : "")}
          style={{ width: 800, marginBottom: spaces.space2, marginRight: spaces.space2 }}
          key={`table-${selectedCustomer}-${locale}`}
        />
      </Space>
    </Flex>
  )
}
