import { Tabs } from "antd"
import { Projections } from "./Projections"
import { Assumptions } from "./Assumptions"
import { Liquidity } from "./Liquidity"

export const PrivateInvestments = () => {
  const items = [
    {
      key: "assumptions",
      label: "Assumptions",
      children: <Assumptions />,
    },
    {
      key: "projections",
      label: "Projections",
      children: <Projections />,
    },
    {
      key: "liquidity",
      label: "Liquidity",
      children: <Liquidity />,
    },
  ]

  return <Tabs defaultActiveKey="assumptions" items={items} style={{ minWidth: "99vw", padding: 0 }} />
}
