import React from "react"
import * as d3 from "d3"
import { colors } from "../../utils/styles"

export const TacticalIndicator = ({ signal = "NEUTRAL", width = 150, height = 20 }) => {
  const signalMap = {
    VERY_BEARISH: -2,
    BEARISH: -1,
    NEUTRAL: 0,
    BULLISH: 1,
    VERY_BULLISH: 2,
  }
  const value = signalMap[signal] ?? 0
  const xScale = d3.scaleLinear().domain([-2, 2]).range([0, width])
  const zeroX = xScale(0)
  const signalX = xScale(value)
  const barWidth = Math.abs(zeroX - signalX)

  return (
    <svg width={width} height={height}>
      <defs>
        <linearGradient id="gradientBear" x1="100%" y1="0%" x2="0%" y2="0%">
          <stop offset="0%" stopColor="#ffcccc" />
          <stop offset="100%" stopColor={colors.primary} />
        </linearGradient>
        <linearGradient id="gradientBull" x1="0%" y1="0%" x2="100%" y2="0%">
          <stop offset="0%" stopColor={colors.softGreen} />
          <stop offset="100%" stopColor={colors.green} />
        </linearGradient>
      </defs>
      <line x1={zeroX} x2={zeroX} y1={0} y2={height} stroke="#000" strokeDasharray="2,2" />
      {value < 0 && <rect x={signalX} y={0} width={barWidth} height={height} fill="url(#gradientBear)" />}
      {value > 0 && <rect x={zeroX} y={0} width={barWidth} height={height} fill="url(#gradientBull)" />}
    </svg>
  )
}
