import { DownloadOutlined } from "@ant-design/icons"
import { Button, Card, Checkbox, Empty, Flex, Form, Select, Statistic, Table, Tooltip } from "antd"
import { useLiquidity } from "../../../hooks/templates/private-investments/useLiquidity"
import { spaces } from "../../../utils/styles"
import { LoaderFullScreen } from "../../atoms/animations/Loader"
import { MoneyLabel } from "../../atoms/formatters/MoneyLabel"
import { EditableNumber } from "../../atoms/input/EditableNumber"
import { LiquidityPlot } from "../../molecules/charts/private-investments/LiquidityPlot"
import { OutflowLabel } from "../../molecules/OutflowLabel"
import { InflowLabel } from "../../molecules/InflowLabel"

export const Liquidity = () => {
  const {
    form,
    customers,
    isCustomerSelected,
    cashflow,
    isLoading,
    classes,
    liquidity,
    availableYears,
    shares,
    expectedDividendsPerShare,
    onValuesChange,
    resetValues,
  } = useLiquidity()

  if (isLoading) return <LoaderFullScreen />

  const columnsClassTable = [
    {
      title: "Class",
      dataIndex: "assetClass",
      render: (assetClass) => (
        <Form.Item name={["liquiditySources", assetClass]} valuePropName="checked">
          <Checkbox>{assetClass}</Checkbox>
        </Form.Item>
      ),
    },
    {
      title: "Current Position",
      dataIndex: "amount",
      render: (amount) => <MoneyLabel value={amount} currency={"USD"} />,
    },
  ]

  const columnsCashflowTable = [
    {
      title: "Semester",
      dataIndex: "semester",
      align: "center",
    },
    {
      title: "Expected Capital Call",
      dataIndex: "contributionUsd",
      align: "center",
      render: (contributionUsd) => <OutflowLabel value={contributionUsd} currency={"USD"} />,
    },
    {
      title: "Expected Distribution",
      dataIndex: "distributionUsd",
      align: "center",
      render: (distributionUsd) => <InflowLabel value={distributionUsd} currency={"USD"} />,
    },
    ...shares.map((share) => ({
      title: share.asset,
      dataIndex: ["dividends", share.asset],
      align: "center",
      hidden: !share.shares,
      render: (dividend) => <InflowLabel value={dividend} currency={"USD"} />,
    })),
    {
      title: "Simulate Inflows",
      align: "center",
      render: (_, row) => (
        <Form.Item name={["inflows", row.semester]} style={{ margin: 0, display: "flex", justifyContent: "center" }}>
          <EditableNumber prefix={"US$"} styleInput={{ width: 150 }} step={1000000} initialValue={0} />
        </Form.Item>
      ),
    },
    {
      title: "Simulate Outflows",
      align: "center",
      render: (_, row) => (
        <Form.Item name={["outflows", row.semester]} style={{ margin: 0, display: "flex", justifyContent: "center" }}>
          <EditableNumber prefix={"US$"} styleInput={{ width: 150 }} step={1000000} initialValue={0} />
        </Form.Item>
      ),
    },
    {
      title: "Balance",
      dataIndex: "balanceUsd",
      align: "center",
      render: (balanceUsd) => (
        <div style={{ color: balanceUsd < 0 ? "red" : "black" }}>
          <MoneyLabel value={balanceUsd} currency={"USD"} />
        </div>
      ),
    },
  ]

  const dividendsColumns = [
    {
      title: "Dividends Per Share",
      key: "asset",
      dataIndex: "asset",
      render: (_, asset) => (
        <Form.Item name={asset.asset} valuePropName="checked">
          <Checkbox>{asset.asset}</Checkbox>
        </Form.Item>
      ),
    },
    ...availableYears.map((year) => ({
      key: year,
      title: year,
      dataIndex: year,
      align: "center",
      render: (_, row) => (
        <Form.Item name={["dividendsPerShare", row.asset, year]} style={{ margin: 0, display: "flex", justifyContent: "center" }}>
          <EditableNumber prefix={"US$"} styleInput={{ width: 85 }} step={0.01} initialValue={row[year]} />
        </Form.Item>
      ),
    })),
  ]

  return (
    <Form form={form} onValuesChange={onValuesChange} style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Flex vertical style={{ padding: spaces.space2, gap: spaces.space2 }}>
        <Flex style={{ gap: spaces.space2 }}>
          <Form.Item name="customer" label="Customer">
            <Select style={{ width: 200 }}>
              {customers.map((customer) => (
                <Select.Option key={customer.id} value={customer.id}>
                  {customer.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Button type="primary" disabled={!isCustomerSelected} onClick={resetValues}>
            Reset Simulation
          </Button>
        </Flex>

        {!isCustomerSelected && <Empty description="Select a customer to simulate the liquidity" image={Empty.PRESENTED_IMAGE_SIMPLE} />}

        {isCustomerSelected && !cashflow.length && (
          <Empty description="Customer doesn't have private investments" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )}

        {isCustomerSelected && cashflow.length > 0 && (
          <Flex vertical style={{ gap: spaces.space2, justifyItems: "center" }}>
            <Flex style={{ gap: spaces.space2 }}>
              <Flex vertical style={{ gap: spaces.space2 }}>
                <Card size="small">
                  <Statistic title="Liquidity Sources" value={liquidity} precision={2} prefix="US$" />
                </Card>
                <Table rowKey="assetClass" columns={columnsClassTable} dataSource={classes} pagination={false} />
              </Flex>
              <Card
                title="Cashflow Forecast"
                // extra={
                //   <Tooltip title="Export plot as SVG" onClick={() => console.log("Exporting plot as SVG")}>
                //     <DownloadOutlined style={{ fontSize: 20, cursor: "pointer" }} />
                //   </Tooltip>
                // }
                styles={{ body: { padding: spaces.space2 } }}
              >
                <LiquidityPlot data={cashflow} />
              </Card>
            </Flex>
            {shares.length > 0 && (
              <Flex style={{ gap: spaces.space2, marginBottom: spaces.space2 }}>
                <Table columns={dividendsColumns} dataSource={expectedDividendsPerShare} pagination={false} style={{ width: 1250 }} size="small" />
              </Flex>
            )}
            <Table
              rowKey="semester"
              columns={columnsCashflowTable}
              size="small"
              dataSource={cashflow}
              pagination={false}
              style={{ maxWidth: 1250 }}
            />
          </Flex>
        )}
      </Flex>
    </Form>
  )
}
