import { XFilled } from "@ant-design/icons"
import { Card, Checkbox, Col, DatePicker, Flex, Form, InputNumber, Modal, Row, Select } from "antd"
import dayjs from "dayjs"
import { cloneElement, useEffect, useState } from "react"
import { useToast } from "../../../hooks/context/useToast"
import { useAssetClass } from "../../../hooks/useAssetClass"
import { ASSET_LOCALES } from "../../../utils/enums"
import { colors, spaces } from "../../../utils/styles"

export const PortfolioModelModal = ({ portfolioModel, onSubmit = () => {}, onClose = () => {} }) => {
  const { getParentAssetClass } = useAssetClass()
  const [offshoreClasses, setOffshoreClasses] = useState([])
  const [onshoreClasses, setOnshoreClasses] = useState([])
  const [form] = Form.useForm()
  const toast = useToast()

  useEffect(() => {
    const preFilled = {
      onshoreWeight: portfolioModel?.onshore?.weight * 100,
      offshoreWeight: portfolioModel?.offshore?.weight * 100,
      date: portfolioModel?.date ? dayjs(portfolioModel.date).add(1, "month") : dayjs().add(1, "month"),
    }

    const fill = ({ assetClassId, weight, tacticalPosition }) => {
      preFilled[`${assetClassId}_weight`] = weight * 100
      preFilled[`${assetClassId}_tacticalPosition`] = tacticalPosition
      preFilled[`${assetClassId}_checked`] = true
    }

    portfolioModel?.onshore?.portfolio.forEach(fill)
    portfolioModel?.offshore?.portfolio.forEach(fill)

    form.setFieldsValue(preFilled)
  }, [portfolioModel])

  useEffect(() => {
    getParentAssetClass(ASSET_LOCALES.onshore.value).then(setOnshoreClasses)
    getParentAssetClass(ASSET_LOCALES.offshore.value).then(setOffshoreClasses)
  }, [])

  const TaticalPositionItem = ({ color, text }) => (
    <Flex style={{ gap: spaces.space1, color }}>
      <XFilled style={{ color }} />
      {text}
    </Flex>
  )

  const TaticalPosition = (
    <Select style={{ width: 170 }}>
      <Select.Option value="VERY_BEARISH">
        <TaticalPositionItem color={colors.primary} text="Strong Underweight" />
      </Select.Option>
      <Select.Option value="BEARISH">
        <TaticalPositionItem color={colors.primary} text="Underweight" />
      </Select.Option>
      <Select.Option value="NEUTRAL">
        <TaticalPositionItem color={colors.blue} text="Neutral" />
      </Select.Option>
      <Select.Option value="BULLISH">
        <TaticalPositionItem color={colors.green} text="Overweight" />
      </Select.Option>
      <Select.Option value="VERY_BULLISH">
        <TaticalPositionItem color={colors.green} text="Strong Overweight" />
      </Select.Option>
    </Select>
  )

  const submit = (values) => {
    const sumOnshore = onshoreClasses
      .map((i) => i.id)
      .reduce((acc, id) => {
        if (values[`${id}_checked`]) return acc + (values[`${id}_weight`] || 0)
        return acc
      }, 0)

    const sumOffshore = offshoreClasses
      .map((i) => i.id)
      .reduce((acc, id) => {
        if (values[`${id}_checked`]) return acc + (values[`${id}_weight`] || 0)
        return acc
      }, 0)

    if (sumOnshore !== 100) return toast.error({ message: "A soma das classes onshore deve ser 100%." })
    if (sumOffshore !== 100) return toast.error({ message: "A soma das classes offshore deve ser 100%." })
    if (values.onshoreWeight + values.offshoreWeight !== 100) return toast.error({ message: "A soma dos pesos onshore/offshore deve ser 100%." })

    onSubmit({ ...values, date: values.date.format("YYYY-MM-01") })
  }

  return (
    <Modal title="Nova Carteira Modelo" cancelText="Cancelar" width={1100} open onOk={() => form.submit()} onCancel={onClose}>
      <Form form={form} onFinish={submit}>
        <Flex vertical style={{ gap: spaces.space2, paddingTop: spaces.space2 }}>
          <Form.Item name="date">
            <DatePicker.MonthPicker />
          </Form.Item>
          <Flex style={{ gap: spaces.space2 }}>
            <Card
              title="Onshore"
              size="small"
              extra={
                <Form.Item name="onshoreWeight">
                  <InputNumber suffix="%" step={1} max={100} min={0} />
                </Form.Item>
              }
              styles={{ body: { display: "flex", flexDirection: "column", gap: spaces.space1 } }}
              style={{ width: 550 }}
            >
              {onshoreClasses.map((item) => (
                <Row gutter={spaces.space1} key={item.id}>
                  <Col span={9}>
                    <Form.Item name={`${item.id}_checked`} valuePropName="checked">
                      <Checkbox>{item.name}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item dependencies={[`${item.id}_checked`]} noStyle>
                      {({ getFieldValue }) => {
                        const isChecked = getFieldValue(`${item.id}_checked`)
                        return (
                          <Form.Item name={`${item.id}_weight`} rules={[{ required: isChecked, message: "Peso obrigatório" }]}>
                            <InputNumber suffix="%" step={1} max={100} min={0} disabled={!isChecked} />
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item dependencies={[`${item.id}_checked`]} noStyle>
                      {({ getFieldValue }) => {
                        const isChecked = getFieldValue(`${item.id}_checked`)
                        return (
                          <Form.Item name={`${item.id}_tacticalPosition`} rules={[{ required: isChecked, message: "Posição obrigatória" }]}>
                            {cloneElement(TaticalPosition, { disabled: !isChecked })}
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Card>
            <Card
              title="Offshore"
              size="small"
              style={{ width: 550 }}
              styles={{ body: { display: "flex", flexDirection: "column", gap: spaces.space1 } }}
              extra={
                <Form.Item name="offshoreWeight">
                  <InputNumber suffix="%" step={1} max={100} min={0} />
                </Form.Item>
              }
            >
              {offshoreClasses.map((item) => (
                <Row gutter={spaces.space1} key={item.id}>
                  <Col span={9}>
                    <Form.Item name={`${item.id}_checked`} valuePropName="checked">
                      <Checkbox>{item.name}</Checkbox>
                    </Form.Item>
                  </Col>
                  <Col span={5}>
                    <Form.Item dependencies={[`${item.id}_checked`]} noStyle>
                      {({ getFieldValue }) => {
                        const isChecked = getFieldValue(`${item.id}_checked`)
                        return (
                          <Form.Item name={`${item.id}_weight`} rules={[{ required: isChecked, message: "Peso obrigatório" }]}>
                            <InputNumber suffix="%" step={1} max={100} min={0} disabled={!isChecked} />
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item dependencies={[`${item.id}_checked`]} noStyle>
                      {({ getFieldValue }) => {
                        const isChecked = getFieldValue(`${item.id}_checked`)
                        return (
                          <Form.Item name={`${item.id}_tacticalPosition`} rules={[{ required: isChecked, message: "Posição obrigatória" }]}>
                            {cloneElement(TaticalPosition, { disabled: !isChecked })}
                          </Form.Item>
                        )
                      }}
                    </Form.Item>
                  </Col>
                </Row>
              ))}
            </Card>
          </Flex>
        </Flex>
      </Form>
    </Modal>
  )
}
